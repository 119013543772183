/* Стили для слайдера */
.frame {
  height: 233px;
/*   line-height: 250px; */
  overflow: hidden;
}
.frame ul {
  list-style: none;
  margin: 0;
  padding: 0;
  height: 100%;
  /* width: 100%;  ????  */
  font-size: 50px;
}
.frame ul li {
  width: 312px;
  overflow: hidden;
  float: left;
  height: 100%;
  margin: 0 1px 0 0;
  padding: 0;
  background: #ccc;
  text-align: center;
  cursor: pointer;
}
.frame ul li a {
  display: block;
}
/* Стили для слайдера на главной */
.pages {
  list-style: none;
  margin: 22px 0;
  padding: 0;
  text-align: center;
}
.pages li {
  display: inline-block;
  width: 8px;
  height: 8px;
  margin: 5px 3px;
  background: #fff;
  border: 1px solid #0296a6;
  @include tranz;
  @include bradius(5px);
  text-indent: -999px;
  cursor: pointer;
  overflow: hidden;
}
.pages li:hover {
  background: #0296a6;
}
.pages li.active {
  background: #0296a6;
}
.controls {
  text-align: center;
  .prevPage ,
  .nextPage {
    cursor: pointer;
    background: none;
    top: 27%;
    width: 22px;
    height: 22px;
    position: absolute;
    display: inline-block;
    zoom: 1;
    overflow: hidden;
    border: 2px solid #0296a6;
    @include bradius(15px);
    @include tranz;
    &:hover {
      border: 2px solid #4dcfe0;
      color: #4dcfe0;
    }
    &:focus {
      border: 2px solid #4dcfe0;
      color: #4dcfe0;
    }
  }
  .prevPage {left: -36px;}
  .nextPage {right: -31px;}
  .prevPage ,
  .nextPage {
    &:before {
      position: absolute;
      font-family: 'Font Awesome 5 Free';
      font-style: normal;
      font-weight: 900;
      text-decoration: inherit;
      font-size: 12px;
      top: 50%;
      margin: -6px 0 0 0; 
      color: #0296a6;
      line-height: 1;
      @include tranz;
    }
  }
  .prevPage {
    &:before {content: "\f053"; left: 6px;}
  }
  .nextPage {
    &:before {content: "\f054"; right: 5px;}
  }

  .prevImg ,
  .nextImg {
    cursor: pointer;
    top: 22%;
    width: 20px;
    background: #fff;
    height: 40px;
    position: absolute;
    display: inline-block;
    zoom: 1;
    overflow: hidden;
    border: none;
    @include bradius(15px);
    @include tranz;
    &:hover {
      color: #4dcfe0;
    }
    &:focus {
      color: #4dcfe0;
    }
  }
  .prevImg {
    -moz-border-radius: 0 12px 12px 0;
    -webkit-border-radius: 0 12px 12px 0;
    -ms-border-radius: 0 12px 12px 0;
    border-radius: 0 12px 12px 0;
  }
  .nextImg {
    -moz-border-radius: 12px 0 0 12px;
    -webkit-border-radius: 12px 0 0 12px;
    -ms-border-radius: 12px 0 0 12px;
    border-radius: 12px 0 0 12px;
  }
  .prevImg {left: 0;}
  .nextImg {right: 0;}
  .prevImg ,
  .nextImg {
    &:before {
      position: absolute;
      font-family: 'Font Awesome 5 Free';
      font-style: normal;
      font-weight: 900;
      text-decoration: inherit;
      font-size: 12px;
      color: #0296a6;
      @include tranz;
    }
  }
  .prevImg {
    &:before {content: "\f053"; margin: 14px 0 0 0; left: 4px;}
  }
  .nextImg {
    &:before {content: "\f054"; margin: 14px 0 0 0; right: 4px;}
  }
}

/* Wobble эффект для стрелок */
@-webkit-keyframes wobble-horizontal {
  20% {
    -webkit-transform: translateX(4px);
    transform: translateX(4px);
  }

  50% {
    -webkit-transform: translateX(-2px);
    transform: translateX(-2px);
  }

  80% {
    -webkit-transform: translateX(1px);
    transform: translateX(1px);
  }

  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}

@keyframes wobble-horizontal {
  20% {
    -webkit-transform: translateX(4px);
    -ms-transform: translateX(4px);
    transform: translateX(4px);
  }

  50% {
    -webkit-transform: translateX(-2px);
    -ms-transform: translateX(-2px);
    transform: translateX(-2px);
  }

  80% {
    -webkit-transform: translateX(1px);
    -ms-transform: translateX(1px);
    transform: translateX(1px);
  }

  100% {
    -webkit-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0);
  }
}
/* 
 */
 @-webkit-keyframes wobble-horizontal-r {
  20% {
    -webkit-transform: translateX(-4px);
    transform: translateX(-4px);
  }

  50% {
    -webkit-transform: translateX(2px);
    transform: translateX(2px);
  }

  80% {
    -webkit-transform: translateX(-1px);
    transform: translateX(-1px);
  }

  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}

@keyframes wobble-horizontal-r {
  20% {
    -webkit-transform: translateX(-4px);
    -ms-transform: translateX(-4px);
    transform: translateX(-4px);
  }

  50% {
    -webkit-transform: translateX(2px);
    -ms-transform: translateX(2px);
    transform: translateX(2px);
  }

  80% {
    -webkit-transform: translateX(-1px);
    -ms-transform: translateX(-1px);
    transform: translateX(-1px);
  }

  100% {
    -webkit-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0);
  }
}
.prevPage ,
.nextPage ,
.prevImg , 
.nextImg {
  &:before {
    display: inline-block;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
    box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  }
}
.prevPage ,
.prevImg {
  &:hover {
    &:before {
      color: #4dcfe0;
      -webkit-animation-name: wobble-horizontal;
      animation-name: wobble-horizontal;
      -webkit-animation-duration: 0.7s;
      animation-duration: 0.7s;
      -webkit-animation-timing-function: ease-in-out;
      animation-timing-function: ease-in-out;
      -webkit-animation-iteration-count: 1;
      animation-iteration-count: 1;
    }
  }
}
.nextPage ,
.nextImg {
  &:hover {
    &:before {
      color: #4dcfe0;
      -webkit-animation-name: wobble-horizontal-r;
      animation-name: wobble-horizontal-r;
      -webkit-animation-duration: 0.7s;
      animation-duration: 0.7s;
      -webkit-animation-timing-function: ease-in-out;
      animation-timing-function: ease-in-out;
      -webkit-animation-iteration-count: 1;
      animation-iteration-count: 1;
    }
  }
}


/* Стили для слайдера заявок */
.feedback__list-cover {
  max-width: 980px;
  position: relative;
  margin: 0 auto;
  .feedback__list {
    height: auto;
    margin-top: 55px;
    padding-bottom: 70px;
    ul {
      font-size: 12px;
      li {
        overflow: visible;
        margin: 0 17px 0 3px;
        width: 180px;
        background: #fff;
        @extend .is-rounded;
        @extend .is-box-shadow;
        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
}
.feedback__list-cover {
  .controls {
    position: absolute;
    z-index: 100;
    top: -25px;
    right: 0;
    width: 80px;
    height: 40px;
    @include bradius(4px);
    @extend .is-box-shadow;
    &:before {
      content: '';
      height: 40px;
      width: 1px;
      position: absolute;
      left: 39px;
      z-index: 100;
      background: #e6e6e6;
    }
  }
  .prevPage, 
  .nextPage {
    top: 0;
    cursor: pointer;
    border: 0;
    width: 40px;
    height: 40px;
    display: inline-block;
    zoom: 1;
    background: #fff;
    overflow: hidden;
    position: relative;
    &:before {
      font-size: 17px;
    }
    &:hover {
      border: 0;
    }
    &:focus {
      border: 0;
    }
  }
  .prevPage {
    @extend .is-b-left;
    left: 0;
    &:before {
      margin: -8px 0 0 0;
      left: 13px;
    }
  }
  .nextPage {
    @extend .is-b-right;
    right: 0;
    &:before {
      margin: -8px 0 0 0;
      right: 12px;
    }
  }
  .scrollbar {
    margin: 50px 0 0 0;
    height: 10px;
    background: transparent;
    line-height: 0;
    position: relative;
    &:before {
      content: '';
      height: 1px;
      position: absolute;
      width: 100%;
      top: 4px;
      left: -20px;
      padding-right: 20px;
      background: rgba(51, 51, 51, 0.2);
    }
  }
  .handle {
    width: 100px;
    height: 100%;
    background: #b3b3b3 url('../img/feedback-handle.png') center center no-repeat;
    cursor: pointer;
    @include bradius(2px);
  }
  .scrollbar .handle .mousearea {
    position: absolute;
    top: -9px;
    left: 0;
    width: 100%;
    height: 20px;
  }
  .scroll-element_size {
  position: relative;
  width: 100%;
  top: 4px;
  left: -20px;
  padding-right: 20px;
  background: transparent;
  &:before {
  content: '';
    height: 1px;
    position: absolute;
    width: 100%;
    top: 4px;
    left: -20px;
    padding-right: 20px;
    background: rgba(51, 51, 51, 0.1);
    }
  }
  .scroll-bar {
    cursor: pointer;
    width: 100px;
    height: 100%;
    background: #ccc url("../img/feedback-handle.png") center center no-repeat;
    cursor: pointer;
    -moz-border-radius: 2px;
    -webkit-border-radius: 2px;
    -ms-border-radius: 2px;
    border-radius: 2px;
  }
  .scrollbar-inner > .scroll-element .scroll-element_track {
    background-color: transparent;
  }
  .scrollbar-inner > .scroll-element.scroll-x {
    margin-bottom: 20px;
    z-index: 500;
  }
}
