$color--success: #00bea5;
$color--alert:   #fee480;

.notify-trigger {
  display: block;
  text-decoration: none;
  padding: .5rem;
  &--success {
    color: $color--success;
  }
  &--alert {
    color: $color--alert;
  }
}

.notify {
	z-index: 20000;
  position: fixed;
	top: 0;
  left: 0;
  right: 0;
	overflow: hidden;
	transition: all .4s ease;
	transform: translateY(-104%);
  box-shadow: 0px 1px 1px 0px rgba(51, 51, 51, 0.17);
	&.is-active {
		transform: translateY(0);
	}
	&--success {
		background: $color--success;
		color: #fff;
	}
	&--alert {
		background: $color--alert;
		color: #fe8e0d;
	}
	&__inner {
		padding: 15px 6%;
		text-align: center;
		line-height: 1.2;
	}
  &__title {
    font-family: 'Arimo', sans-serif;
    padding-bottom: .3rem;
    text-align: center;
    font-size: 18px;
    font-weight: bold;
    margin: 0;
	}
	&__text {
    font-family: 'Arimo', sans-serif;
    font-size: 12px;
    padding: 0 .5rem;
    display: inline-block;
    text-align: center;
    margin: 0;
	}
}