// Mixins

@mixin bradius ($radius) {
	-moz-border-radius: $radius;
  	-webkit-border-radius: $radius; 
  	-ms-border-radius: $radius; 
  	border-radius: $radius;
}
@mixin bsizing {
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
}
@mixin tranz {
    transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
}

@mixin mtop($multiplier: 1, $firstlast: true){
	margin-top: $base-vertical-margin * $multiplier;
	@if $firstlast {
		&:first-child { margin-top: 0; }
	}
}

@mixin mbot($multiplier: 1, $firstlast: true){
	margin-bottom: $base-vertical-margin * $multiplier;
	@if $firstlast {
		&:last-child { margin-bottom: 0; }
	}
}

@mixin margin($multiplier: 1, $firstlast: true){
	margin-top: $base-vertical-margin * $multiplier;
	margin-bottom: $base-vertical-margin * $multiplier;
	@if $firstlast {
		&:first-child { margin-top: 0; }
		&:last-child { margin-bottom: 0; }
	}
}

@mixin ptop($multiplier: 1){
	padding-top: $base-vertical-margin * $multiplier;
}

@mixin pbot($multiplier: 1){
	padding-bottom: $base-vertical-margin * $multiplier;
}

@mixin padding($multiplier: 1){
	padding-top: $base-vertical-margin * $multiplier;
	padding-bottom: $base-vertical-margin * $multiplier;
}

@mixin inline-block {
	display: -moz-inline-stack;	// ff 2
	display: inline-block;
	zoom:1; *display: inline; _height: 15px; // ie 6-7
}

@mixin box-margin($margin) {
	margin-top: $margin;
	margin-bottom: $margin;
	&:first-child {
		margin-top: 0;
	}
	&:last-child {
		margin-bottom: 0;
	}
}

@mixin triangle-down($width, $height, $color){
	width: 0;
	height: 0;
	border-style: solid;
	border-width: $height $width/2 0 $width/2;
	border-color: $color transparent transparent transparent;
}

@mixin fontface($name, $weight, $style, $fontname) {
	font-family: $name;
    src: url('../fonts/' + $fontname + '.eot');
    src: url('../fonts/' + $fontname + '.eot?#iefix') format('embedded-opentype'),
         url('../fonts/' + $fontname + '.woff') format('woff');
    font-weight: $weight;
    font-style: $style;
}

@mixin retinabg($url, $url2x, $size, $position, $repeat) {
	background-image: url($url);
	background-repeat: $repeat;
	background-position: $position;
	@media #{$media-retina} {
		background-image: url($url2x);
		background-size: $size;
	}
}

@mixin fontsize($size, $lineheight: $line-height-base){
	font-size: $size;
	line-height: $lineheight;
}