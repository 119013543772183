.ui-datepicker {
    display: none;
    margin-top: 4px;
    padding: 10px;
    width: 240px;
    z-index: 1000 !important;
    background: #fff !important;
    height: auto !important;
    @include bradius(4px);
    @extend .is-box-shadow;

}
.ui-datepicker .ui-widget-header {
  background: none !important;
  height: auto !important;
}
.ui-datepicker a,
.ui-datepicker a:hover {
    text-decoration: none;
}
.ui-datepicker a:hover,
.ui-datepicker td:hover a {
    color: #26c5d9;
    -webkit-transition: color 0.3s ease-in-out;
       -moz-transition: color 0.3s ease-in-out;
         -o-transition: color 0.3s ease-in-out;
            transition: color 0.3s ease-in-out;
}
.ui-datepicker .ui-datepicker-header {
    margin-bottom: 4px;
    text-align: center;
}
.ui-datepicker .ui-datepicker-title {
    font-weight: 700;
}
.ui-datepicker .ui-datepicker-prev,
.ui-datepicker .ui-datepicker-next {
    cursor: pointer;
    font-family: FontAwesome;
    font-style: normal;
    font-weight: normal;
    text-decoration: inherit;
    font-size: 12px;
    color: #0296a6;
    transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    height: 20px;
    width: 20px;
}
.ui-datepicker .ui-datepicker-prev {
    float: left;
    text-align: left;
}
.ui-datepicker .ui-datepicker-next {
    float: right;
    text-align: right;
}
.ui-datepicker .ui-datepicker-prev:before {
    content: "\f053";
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
}
.ui-datepicker .ui-datepicker-next:before {
    content: "\f054";
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
}
.ui-datepicker .ui-icon {
    display: none;
}
.ui-datepicker .ui-datepicker-calendar {
  table-layout: fixed;
    width: 100%;
}
.ui-datepicker .ui-datepicker-calendar th,
.ui-datepicker .ui-datepicker-calendar td {
    text-align: center;
    padding: 4px 0;
    color: #000 !important;
}
.ui-datepicker .ui-datepicker-calendar td {
    border-radius: 4px;
    -webkit-transition: background-color 0.1s ease-in-out, color 0.1s ease-in-out;
       -moz-transition: background-color 0.1s ease-in-out, color 0.1s ease-in-out;
         -o-transition: background-color 0.1s ease-in-out, color 0.1s ease-in-out;
            transition: background-color 0.1s ease-in-out, color 0.1s ease-in-out;
}
.ui-datepicker .ui-datepicker-calendar td:hover:not(.ui-datepicker-current-day) {
    background-color: #eee;
    cursor: pointer;
}
.ui-datepicker .ui-datepicker-calendar td a {
    text-decoration: none;
    color: #000;
}
.ui-datepicker .ui-datepicker-current-day {
    background-color: #26c5d9;
}
.ui-datepicker td.ui-datepicker-current-day a {
    color: #fff;
}
.ui-datepicker .ui-datepicker-calendar .ui-datepicker-unselectable:hover {
    background-color: #fff;
    cursor: default;
}
.ui-datepicker-month {
    border: 0px;
    color: #000;
}
.ui-datepicker-year {
    border: 0px;
    color: #000;
}

/* dialog resizer handle  */
.ui-resizable-handle {
    position: absolute;
    font-size: 0.1px;
    display: block;
    -ms-touch-action: none;
    touch-action: none;
}
.ui-resizable-disabled .ui-resizable-handle,
.ui-resizable-autohide .ui-resizable-handle {
    display: none;
}
.ui-resizable-n {
    cursor: n-resize;
    height: 13px;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 1000 !important;
}
