html {
  height: auto !important;
}
header {
  display: none !important;
}
.main-features,
.requests-step__line,
.requests-info__block,
.requests-step__line,
.requests-eq__block,
.page-content-form__right {
  display: none !important;
}
.page-content-wide, .page-content-form {
  width: 100% !important;
  margin: 0 !important;
}
main {
  display: none;
  margin-top: 0 !important;
}
.feedback__nums-block {
  padding-top: 0 !important;
}
.feedback__img {
  display: none !important;
}
.feedback__head {
  height: auto;
}
.js-partner__open_chat {
  display: none !important;
}
.feedback__info {
  margin-left: 0 !important;
  padding: 10px !important;
}
.feedback__list {
  margin-top: 16px !important;
}
body,
.no-print {
  visibility:hidden;
  height: auto !important;
}

.feedback__main-title {
  border-bottom: 1px solid #ccc;
  color: #333;
}
.print {
  position: absolute;
  display: block;
  visibility: visible;
  top: 10px;
  height: auto;
  width: 100%;

  &__logo {
    border-top: 1px solid black;
    border-bottom: 1px solid black;
    padding: 20px 0;
    width: 100%;
    text-align: center;
  }
  &__h1 {
    text-align: center;
    font-size: 26px;
    font-weight: bold;
    padding: 50px 0 50px;
  }
  table {
    width: 100%;
    tr {
      th, td {
        text-align: left;
        border: 1px solid #ccc;
        padding: 5px 10px;
      }
    }
  }
}