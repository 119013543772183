.search {

    &--value {
        font-size: 13px;
        display: inline-block;
        margin-bottom: 3px;
    }
    &--location {
        color: #999;
    }

    &--right-col {
        position: absolute;
        top: 5px;
        right: 10px;
        text-align: right;
    }
    &--news {
        &--date {
            display: inline-block;
            font-size: 12px;
            color: #999;
            margin-right: 15px;
            margin-bottom: 5px;
        }
        &--show_all {
            display: inline-block;
            text-align: center;
            width: 100%;
            background: #02abc0;
            color: #fff;
            padding: 10px 0;
            font-size: 12px;
        }
    }
    &--not-found {
        display: block;
        position: absolute;
        font-size: 12px;
        color: #ff6e09;
        background: #fff;
        margin: 0 auto;
        text-align: center;
        font-weight: bold;
        padding: 7px 0;
        width: 100%;
        top: 0;
        left: 0;
        cursor: default;
    }
    &--show-all {
        display: block;
        position: absolute;
        font-size: 12px;
        color: #fff;
        background: #4dcfe0;
        margin: 0 auto;
        text-align: center;
        font-weight: bold;
        padding: 10px 0;
        width: 100%;
        bottom: -20px;
        left: 0;
        cursor: pointer;
        &:hover {
            color: #0296a6;
            background: #b1eaf1;
        }
    }
    &--delimiter {
        color: #999;
        font-weight: bold;
        &:hover {
            color: #999;
            background: none;
        }
    }
}

.autocomplete-suggestion {
    position: relative;
    clear: both;
}

.suggestion {
    &--avarat-container {
        position:relative;
        display: inline-block;
        height: 50px;
        width: 40px;
        margin-right: 10px;
        font-size: 27px;
        text-align: center;
        float: left;
        color: #02abc0;
        &--image {
            height: 40px;
            width: 40px;
            position: absolute;
            top: -4px;
            left: 0px;
        }
    }

}