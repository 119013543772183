.eq__checkbox , 
.advpost__checkbox ,
.request__checkbox ,
.req-status__checkbox {
	vertical-align: middle;
	margin: 0;
	width: 14px;
	height: 14px;
}
.radio + .radio__label , 
.eq__checkbox + .eq__label , 
.advpost__checkbox + .advpost__label-c ,
.show__checkbox + .show__label-c ,
.request__checkbox + .request__label-c ,
.req-status__checkbox + .req-status__label {
	line-height: 1;
	cursor: pointer;
}
.radio:not(checked) ,
.eq__checkbox:not(checked) , 
.advpost__checkbox:not(checked) ,
.show__checkbox:not(checked) ,
.request__checkbox:not(checked) ,
.req-status__checkbox:not(checked) { 
	position: absolute;
	opacity: 0;
}
.eq__checkbox:not(checked) + .eq__label , 
.advpost__checkbox:not(checked) + .advpost__label-c ,
.show__checkbox:not(checked) + .show__label-c ,
.request__checkbox:not(checked) + .request__label-c ,
.req-status__checkbox:not(checked) + .req-status__label {
	position: relative; 
	padding: 0 0 0 25px; 
}
.req-status__checkbox:disabled + .req-status__label ,
.req-status__checkbox:disabled + .req-status__label:before {
	color: #bdccd4 !important;
}
.req-status__checkbox:disabled + .req-status__label:before {
	border: 1px solid #bdccd4 !important;
}
.eq__checkbox:not(checked) + .eq__label:before ,
.advpost__checkbox:not(checked) + .advpost__label-c:before ,
.show__checkbox:not(checked) + .show__label-c:before ,
.request__checkbox:not(checked) + .request__label-c:before ,
.req-status__checkbox:not(checked) + .req-status__label:before {
	content: '';
	position: absolute;
	top: -2px;
	left: 0;
	width: 14px;
	height: 14px;
	border-radius: 4px;
	background: #fff;
	border: 1px solid #ccc;
}
.eq__checkbox:checked + .eq__label:before ,
.advpost__checkbox:checked + .advpost__label-c:before ,
.show__checkbox:checked + .show__label-c:before ,
.request__checkbox:checked + .request__label-c:before ,
.req-status__checkbox:checked + .req-status__label:before {
	position: absolute;
	left: 0;
	width: 14px;
	height:14px;
	content: "\f00c";
	font-family: 'Font Awesome 5 Free';
	font-style: normal;
	font-weight: 900;
    font-size: 10px;
    line-height: 15px;
	text-decoration: inherit;
    z-index: 100;
    cursor: pointer;
    color: #0296a6;
    text-align: center;
    border: 1px solid #0296a6;
}

/* Radiobtn */
.radio {
	vertical-align: middle;
	margin: 0;
	width: 10px;
	height: 10px;
}
.radio:not(checked) + .radio__label {
	position: relative; 
	padding: 0 0px 0 17px;
}
/* .radio:checked + .radio__label {
	position: relative; 
	padding: 0 3px 0 17px;
} */
.radio:not(checked) + .radio__label:before {
	content: '';
	position: absolute;
	top: 0;
	left: 0;
	width: 10px;
	height: 10px;
    z-index: 100;
    color: #333;
    border: 1px solid #333;
    @include bradius(5px);
}
.radio:checked + .radio__label {
	position: relative; 
	padding: 0 0px 0 17px;
}
.radio:checked + .radio__label:before {
	position: absolute;
	left: 0;
	top: 0;
	width: 10px;
	height:10px;
	content:   "";
	background: url('../img/radio.png') center no-repeat;
    z-index: 100;
    cursor: pointer;
    font-size: 9px;
    color: #333;
    text-align: center;
}