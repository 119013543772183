@charset "UTF-8";
@import "../../node_modules/@fancyapps/fancybox/dist/jquery.fancybox.min.css";
.fancybox-content {
    padding: 0;
    border-radius: 4px;
}

$fa-font-path: "../fonts" !default;
@import "../../node_modules/@fortawesome/fontawesome-free/scss/fontawesome";
@import "../../node_modules/@fortawesome/fontawesome-free/scss/regular";
@import "../../node_modules/@fortawesome/fontawesome-free/scss/solid";
@import "../../node_modules/@fortawesome/fontawesome-free/scss/brands";

@import "../../node_modules/noty/lib/noty.css";
@import "../../node_modules/noty/lib/themes/relax.css";

@import "../../node_modules/normalize.css/normalize.css";

@import "../../node_modules/selectize/dist/css/selectize.css";
@import 'desktop/mixins';
@import 'desktop/home_modal';
@import 'desktop/sly-slider';
@import 'desktop/scroll';
@import 'desktop/check';
@import 'desktop/upload';
@import 'desktop/ui';
@import 'desktop/notif';
@import 'desktop/tinymce';
@import 'desktop/search';

@import 'trash/roundslider.min.css';

@import "trash/material.css";

* {
    margin: 0;
    padding: 0;
    outline: none;
}

.news-advpost__text {
    table {
        width: 100%;
    }
    img {
        max-width: 100%;
        height: auto;
    }
}

.fancybox-container {
    z-index: 15000;
}
.noty_theme__relax.noty_bar .noty_body {
    padding: 0;
}

.preloader,
#preloader {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #fff;
    z-index: 19999;

    &__img {
        width: auto;
        height: auto;
        position: absolute;
        left: 49%;
        top: 47%;
    }
}

.preloader {
  opacity: 0.7;
}
a img {
  border: none; /* IE */
}
a {
  text-decoration: none;
}

input ,
select ,
textarea {
  &:hover {
    outline: none !important;
    outline: 0 !important;
    -moz-box-shadow: none !important;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
  }
  &::active {
    outline: none !important;
    outline: 0 !important;
    -moz-box-shadow: none !important;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
  }
  &:active {
    outline: none !important;
    outline: 0 !important;
    -moz-box-shadow: none !important;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
  }
  &:focus {
    outline: none !important;
    outline: 0 !important;
    -moz-box-shadow: none !important;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
  }
  &:required {
    outline: none !important;
    outline: 0 !important;
    -moz-box-shadow: none !important;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
  }
  &:invalid {
    outline: none !important;
    outline: 0 !important;
    -moz-box-shadow: none !important;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
  }
}
ul {
  list-style: none;
}
select {
  cursor: pointer;
  /*   -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none; */
}
select {
  &:hover {
    cursor: pointer;
  }
}

input::-ms-clear {
  display: none;
}
textarea {
  min-height: 35px;
}
input ,
select ,
textarea {
  &::-webkit-input-placeholder {
    color: #ccc;
  }
  &:-moz-placeholder {
    color: #ccc;
  }
  &::-moz-placeholder {
    color: #ccc;
  }
  &:-ms-input-placeholder  {
    color: #ccc !important;
  }
}
input[type="number"] {
  -moz-appearance: textfield;
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}
input[type="number"] ,
input[type="number"]:hover,
input[type="number"]:focus {
  -moz-appearance: textfield !important;
  &::-webkit-input-placeholder {
    color: #ccc;
  }
  &:-moz-placeholder {
    color: #ccc;
  }
  &::-moz-placeholder {
    color: #ccc;
  }
  &:-ms-input-placeholder  {
    color: #ccc !important;
  }
}

@font-face {
  font-family: 'Comfortaa';
  src: url('../fonts/Comfortaa-Regular.eot');
  src: url('../fonts/Comfortaa-Regular.eot?#iefix') format('embedded-opentype'), url('../fonts/Comfortaa-Regular.woff') format('woff'), url('../fonts/Comfortaa-Regular.ttf') format('truetype'), url('../fonts/Comfortaa-Regular.svg#cuprumregular') format('svg');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Comfortaa';
  src: url('../fonts/Comfortaa-Bold.eot');
  src: url('../fonts/Comfortaa-Bold.eot?#iefix') format('embedded-opentype'), url('../fonts/Comfortaa-Bold.woff') format('woff'), url('../fonts/Comfortaa-Bold.ttf') format('truetype'), url('../fonts/Comfortaa-Bold.svg#cuprumbold') format('svg');
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: 'Comfortaa';
  src: url('../fonts/Comfortaa-Light.eot');
  src: url('../fonts/Comfortaa-Light.eot?#iefix') format('embedded-opentype'), url('../fonts/Comfortaa-Light.woff') format('woff'), url('../fonts/Comfortaa-Light.ttf') format('truetype'), url('../fonts/Comfortaa-Light.svg#cuprumbold_italic') format('svg');
  font-weight: lighter;
  font-style: normal;
}
@font-face {
  font-family: 'als_rublregular';
  src: url('../fonts/rouble_0-webfont.eot');
  src: url('../fonts/rouble_0-webfont.eot?#iefix') format('embedded-opentype'), url('../fonts/rouble_0-webfont.woff') format('woff'), url('../fonts/rouble_0-webfont.ttf') format('truetype'), url('../fonts/rouble_0-webfont.svg#als_rublregular') format('svg');
  font-weight: normal;
  font-style: normal;
}


html, body {
  margin: 0;
  padding: 0;
  height: 100%;
}
body {
  background: #f2f5f6;
  color: #4d4d4d;
  font-family: 'Arimo', sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 1.2;
  -webkit-font-smoothing: subpixel-antialiased !important;
}
body.main-page {
  background-color: #ffffff;
  height: auto;
  min-height: 100%;
  position: relative;
}

main.feedback {
  &--nums-1 {
    &:before {
      height: 221px;
    }
  }
  &--nums-2 {
    &:before {
      height: calc(221px + 60px);
    }
  }
  &--nums-3 {
    &:before {
      height: calc(221px + 120px);
    }
  }
  &--nums-4 {
    &:before {
      height: calc(221px + 180px);
    }
  }
  &--nums-5 {
    &:before {
      height: calc(221px + 240px);
    }
  }
}
main.feedback {
  position: relative;
  height: 100%;
  &:before {
    content: '';
    position: absolute;
    top: 60px;
    width: 100%;
    background: url('../img/feedback-bg.png') repeat-x;
  }
  .main-features {
    position: relative;
    height: 100%;
  }
  .main-menu__list {
    @include bradius(5px);
    .current {
      cursor: pointer;
      display: inline-block;
      color: #0296a6;
      background: transparent;
      padding: 11px;
      display: block;
      font-size: 12px;
      text-decoration: none;
      border-top: none;
      border-left: none;
      border-right: none;
      border-bottom: 1px solid #dff6f9;
      .far, .fas {
        font-size: 17px;
        margin-right: 11px;
        display: inline-block;
      }
    }
    li {
      overflow:hidden;
      height:0;
      -webkit-transition:height 300ms ease-in;
      -moz-transition:height 300ms ease-in;
      -o-transition:height 300ms ease-in;
      transition:height 300ms ease-in;
      .active {
        background: transparent;
        color: #0296a6;
        border-bottom: 1px solid transparent;
      }
    }
    &:hover {
      li {
        height: 41px;
        .is-first-item {
          @include bradius(0px);
        }
        .is-last-item {
          height: 40px;
          border-left: none;
          border-right: none;
          border-bottom: none;
        }
        .active {
          background: #4dcfe0;
          color: #fff;
          border-bottom: 1px solid #26c5d9;
        }
      }
    }
  }
}
/* Стандартные классы */
.or-btn {
  background: #fe9f0f;
  color: #fff;
  font-size: 12px;
  text-decoration: none;
  height: 30px;
  line-height: 2.5;
  text-align: center;
  padding: 0 50px;
  @include bradius(4px);
}
.gr-btn {
  display: block;
  background: #E8E8E8;
  color: #333;
  width: 100%;
  font-size: 12px;
  text-decoration: none;
  height: 32px;
  line-height: 2.5;
  text-align: center;
  @include bradius(4px);
  &:hover {
    background: #E2E2E2;
  }
}
.clear {
  &:after {
    content: '';
    display: block;
    height: 0;
    clear: both;
    visibility: hidden;
  }
}
.is-fade {
  @include tranz;
}
.is-rounded {
  @include bradius(4px);
}
.is-box-shadow {
  box-shadow: 0px 1px 1px 0px rgba(51, 51, 51, 0.17);
}
.is-box-shadow-bold {
  box-shadow: 0px 1px 3px 0px rgba(51, 51, 51, 0.3);
}
.is-box-shadow-semi {
  box-shadow: 0px 0px 3px 1px rgba(51, 51, 51, 0.5) !important;
}
.is-b-left {
  -moz-border-radius: 4px 0 0 4px;
  -webkit-border-radius: 4px 0 0 4px;
  -ms-border-radius: 4px 0 0 4px;
  border-radius: 4px 0 0 4px;
}
.is-b-right {
  -moz-border-radius: 0 4px 4px 0;
  -webkit-border-radius: 0 4px 4px 0;
  -ms-border-radius: 0 4px 4px 0;
  border-radius: 0 4px 4px 0;
}
.is-last-el {
  margin-bottom: 0 !important;
}
.is {
  &-mtop {
    &-10 {
      margin-top: 10px;
    }
    &-20 {
      margin-top: 20px;
    }
    &-30 {
      margin-top: 30px;
    }
  }
  &-mbtm {
    &-10 {
      margin-bottom: 10px;
    }
    &-20 {
      margin-bottom: 20px;
    }
    &-30 {
      margin-bottom: 30px;
    }
  }
}

.i-left-20 {
  font-size: 20px;
  margin-right: 4px;
  display: inline-block;
  vertical-align: middle;
}
.i-left-15 {
  font-size: 15px;
  margin-right: 4px;
  display: inline-block;
  vertical-align: baseline;
}
.is-or-text {
  color: #fe8e0d;
}
.is-dgrey-text {
  color: rgba(51, 51, 51, 0.75);
}
.is-blue-text {
  color: #02abc0;
}
.is-grey-text {
  color: rgba(51, 51, 51, 0.75);
}
.is-red-text {
  color: #ff6c08;
}
.is-lblue-text {
  color: #a3ced5;   // удалить
}
.is-over-submit {
  display: inline-block;
  position: relative;
}
.is-gblue-link {
  @include tranz;
  span {
    color: #02abc0;
    @include tranz;
    border-bottom: 1px solid transparent;
  }
  i {
    color: rgba(51, 51, 51, 0.75);
    @include tranz;
  }
  &:hover {
    span {
      color:#0296a6;
      border-bottom: 1px solid #0296a6;
    }
    i {
      color: rgba(51, 51, 51, 0.75);
    }
  }
  &:active {
    span {
      color: #02abc0;
      border-bottom: 1px solid #02abc0;
    }
    i {
      color: rgba(51, 51, 51, 1);;
    }
  }
}
.is-grey-link {
  @include tranz;
  span ,
  i {
    color: rgba(51, 51, 51, 0.75);
    @include tranz;
    border-bottom: 1px solid transparent;
  }
  &:hover {
    span {
      color: rgba(51, 51, 51, 0.75);
      border-bottom: 1px solid rgba(51, 51, 51, 0.50);
    }
    i {
      color: rgba(51, 51, 51, 0.75);
    }
  }
  &:active {
    span {
      color: rgba(51, 51, 51, 1);;
      border-bottom: 1px solid rgba(51, 51, 51, 1);;
    }
    i {
      color: rgba(51, 51, 51, 1);;
    }
  }
}
.is-lgrey-link {
  @include tranz;
  span ,
  i {
    color: #999;
    @include tranz;
    border-bottom: 1px solid transparent;
  }
  &:hover {
    span {
      color: rgba(51, 51, 51, 0.50);
      border-bottom: 1px solid rgba(51, 51, 51, 0.50);
    }
    i {
      color: rgba(51, 51, 51, 0.50);
    }
  }
  &:active {
    span {
      color: rgba(51, 51, 51, 0.75);;
      border-bottom: 1px solid rgba(51, 51, 51, 0.75);;
    }
    i {
      color: rgba(51, 51, 51, 0.75);;
    }
  }
}
.is-white-link {
  @include tranz;
  span ,
  i {
    color: #fff;
    @include tranz;
    border-bottom: 1px solid transparent;
  }
  &:hover {
    span {
      color:#fff;
      border-bottom: 1px solid #fff;
    }
    i {
      color:#fff;
    }
  }
  &:active {
    span {
      color: #fff;
      border-bottom: 1px solid #fff;
    }
    i {
      color: #fff;
    }
  }
}
.is-blue-link {
  @include tranz;
  span ,
  i {
    color: #02abc0;
    @include tranz;
    border-bottom: 1px solid transparent;
  }
  &:hover {
    span {
      color:#0296a6;
      border-bottom: 1px solid #0296a6;
    }
    i {
      color:#0296a6;
    }
  }
  &:active {
    span {
      color: #02abc0;
      border-bottom: 1px solid #02abc0;
    }
    i {
      color: #02abc0;
    }
  }
}
.is-or-link {
  @include tranz;
  span ,
  i {
    color: #fe8e0d;
    @include tranz;
    border-bottom: 1px solid transparent;
  }
  &:hover {
    span {
      color: #ff6e09;
      border-bottom: 1px solid #ff6e09;
    }
    i {
      color: #ff6e09;
    }
  }
  &:active {
    span {
      color: #fe8e0d;
      border-bottom: 1px solid #fe8e0d;
    }
    i {
      color: #fe8e0d;
    }
  }
}
.is-placeholder {
  color: #d0d0d0 !important;
}
.is-first-item {
  -moz-border-radius: 4px 4px 0 0;
  -webkit-border-radius: 4px 4px 0 0;
  -ms-border-radius: 4px 4px 0 0;
  border-radius: 4px 4px 0 0;
}
.is-last-item {
  -moz-border-radius: 0 0 4px 4px;
  -webkit-border-radius: 0 0 4px 4px;
  -ms-border-radius: 0 0 4px 4px;
  border-radius: 0 0 4px 4px;
}
.is-bl-item {
  -moz-border-radius: 0 0 0 5px;
  -webkit-border-radius: 0 0 0 5px;
  -ms-border-radius: 0 0 0 5px;
  border-radius: 0 0 0 5px;
}
.is-br-item {
  -moz-border-radius: 0 0 7px 0;
  -webkit-border-radius: 0 0 7px 0;
  -ms-border-radius: 0 0 7px 0;
  border-radius: 0 0 7px 0;
}
.is-long-row {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.is-no-select {
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}
.center {
  text-align: center;
}
/* Стили для загрузки фото */
.modal__img {
  #filelist,
  #filelist_news,
  #filelist_offers,
  #filelist_equipment,
  #filelist_msg,
  #filelist_msg_modal {
    max-height: 210px !important;
    overflow-y: scroll !important;
    li {
      cursor: pointer;
      &.active {
        border: 3px solid rgba(255, 110, 9, 0.8);
      }
    }
  }
}
.loaded-img__btn {
  width: 150px;
  display: inline-block;
  vertical-align: middle;
  margin-left: 5px;
  color: #fff;
  height: 30px;
  line-height: 30px;
  margin-top: -1px;
}
.filelist,
#filelist_news,
#filelist_offers,
#filelist_equipment,
#filelist_msg,
#filelist_msg_modal,
#filelist {
  margin: 0;
  img {
    max-height: 100px;
    max-width: 100px;
  }
  li {
    border-radius: 2px;
    position: relative;
    text-align: center;
    float: left;
    width: 100px;
    height: 100px;
    line-height: 1;
    overflow: hidden;
    margin: 0 8px 8px 0;
    border: 1px solid #d6d6d6;
    @include bsizing;
  }
  span {
    position: absolute;
    right: 0.3em;
    background: #000;
    color: #f3f1ed;
    padding: 0 0.25em;
    border-radius: 2px;
  }

  .remove {
    cursor: pointer;
    position: absolute;
    right: 3px;
    top: 3px;
    background: rgba(51, 51, 51, 0.7);
    color: #fff;
    width: 20px;
    height: 20px;
    border: 1px solid #fff;
    @include bradius(50%);
    @include bsizing;
    display: block;
    &:before {
      content: "\f00d";
      font-family: 'Font Awesome 5 Free';
      font-style: normal;
      font-weight: 900;
      text-decoration: inherit;
      position: absolute;
      top: 3px;
      right: 4px;
      z-index: 100;
      font-size: 12px;
      color: #fff;
    }
  }
  .edit {
    display: none;
  }
  &.has-edit {
    .edit {
      position: absolute;
      right: 3px;
      bottom: 4px;
      background: rgba(51, 51, 51, 0.7);
      color: #fff;
      cursor: pointer;
      width: 20px;
      height: 20px;
      border: 1px solid #fff;
      @include bradius(50%);
      @include bsizing;
      display: block;
      &:before {
        content: "\f304";
        font-family: 'Font Awesome 5 Free';
        font-style: normal;
        font-weight: 900;
        text-decoration: inherit;
        position: absolute;
        top: 2px;
        right: 3px;
        z-index: 100;
        font-size: 11px;
        color: #fff;
      }
    }
  }
}

.dialog-page {
  //  .filelist__clone
  #filelist_msg
  {
    width: 120px;
    position: absolute;
    right: 0;
    height: 100px;
    overflow: hidden;
    li {
      margin: 0 6px 6px 0;
      width: 45px;
      height: 45px;
      img {
        height: 45px;
        width: 45px;
      }
    }

  }
}

.filelist__clone ,
.filelist__clone_modal {
  li {
    display: block;
    height: 100px;
    width: 100px;
    img {
      display: block;
      margin: 0 auto;
      max-height: 100px;
      max-width: 100px;
    }
  }

}

.resizer-bar {
  position: absolute;
  top: 0;
  left: 50%;
  margin: 3px 0 0 -20px;
  .resizer_line {
    width: 40px;
    border-top: 1px solid #ccc;
    border-bottom: 1px solid #fff;
    margin: 0 auto 1px;
  }
}
/*  */
.container {
  width: 1180px;
  height: 100%;
  margin: 0 auto;
  position: relative;
  &:after {
    content: '';
    display: block;
    height: 0;
    clear: both;
    visibility: hidden;
  }
}
/* Header main page */
.header__topbar {
  height: 69px;
  width: 100%;
  margin: 0 auto;
  background: url('../img/header__topbar--bg.jpg') top left repeat-x;
  position: relative;
  z-index: 30;
}
.header__company {
  display: inline-block;
  width: 40%;
  img {
    display: inline-block;
    margin: 11px 10px 0 10px;
    float: left;
  }
  div {
    font-family: 'Comfortaa', sans-serif;
    margin-top: 25px;
    color: #fff;
    font-size: 12px;
  }
}
.header__user-panel {
  float: right;
  form {
    margin-top: 20px;
  }
  input,
  select {
    height: 30px;
    position: relative;
    font-size: 12px;
    border: none;
    @include bradius(4px);
  }
  input[type="tel"],
  select {
    margin-left: 5px;
  }
  .is-over-submit {
    margin-left: 8px;
    float: right;
  }
  .user-city__wrap {
    display: inline-block;
    position: relative;
    cursor: pointer;
    i {
      color: #04e4fe;
      position: absolute;
      z-index: 10;
      right: 8px;
      top: 8px;
    }
  }
  #tmp-select {
    display : none;
  }
  .user-city {
    cursor: pointer;
    padding: 0 0 0 5px;
    width: 100px;
    color: #fff;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background: #015964 url('../img/select__arrow-blue.png') right 50% no-repeat;
    &::-webkit-input-placeholder {
      color: #fff;
    }
    &:-moz-placeholder {
      color: #fff;
    }
    &::-moz-placeholder {
      color: #fff;
    }
    &:-ms-input-placeholder  {
      color: #fff;
    }
  }
  @media screen and (min-width:0\0) {
    .user-city {
      padding: 0 20px 0 5px;
    }
  }
  @-moz-document url-prefix() {
    .user-city {
      cursor: pointer;
      padding: 0 8px 0 5px;
      width: 100px;
    }
  }
  .user-phone-num {
    padding: 0 10px;
    color: #fff;
    width: 165px;
    background: -moz-linear-gradient(top,  #015964 0%, #015560 100%);
    background: -webkit-linear-gradient(top,  #015964 0%,#015560 100%);
    background: linear-gradient(to bottom,  #015964 0%,#015560 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#015964', endColorstr='#015560',GradientType=0 );
    &::-webkit-input-placeholder {
      color: rgba(223, 246, 249, 0.5);
    }
    &:-moz-placeholder {
      color: rgba(223, 246, 249, 0.5);
    }
    &::-moz-placeholder {
      color: rgba(223, 246, 249, 0.5);
    }
    &:-ms-input-placeholder  {
      color: rgba(223, 246, 249, 0.5);
    }
  }
  .user-reg-submit {
    width: 180px;
  }
}

.header__main {
  position: relative;
  height: 380px;
  width: 100%;
  margin: 0 auto;
  background: url('../img/header__main--bg.jpg') top left repeat-x;
  overflow: hidden;
}

.header__project-about ,
.main-menu__list {
  width: 180px;
  position: absolute;
  z-index: 30;
  top: 20px;
  background: #fff;
  @include bradius(5px);
  box-shadow: none;
  li {
    list-style: none;
    a {
      background: transparent;
      color: #0296a6;
      padding: 13px 11px;
      display: block;
      font-size: 12px;
      text-decoration: none;
      border-top: none;
      border-left: none;
      border-right: none;
      border-bottom: 1px solid #dff6f9;
      &:hover {
        background: #b1eaf1;
        border-bottom: 1px solid transparent;
        &.is-last-item {
          border-left: none;
          border-right: none;
          border-bottom: none;
        }
      }
      &:focus {
        background: #b1eaf1;
        border-bottom: 1px solid transparent;
      }
    }
    .active {
      background: #4dcfe0;
      color: #fff;
      border-left: none;
      border-right: none;
      border-bottom: 1px solid #26c5d9;
      &:hover {
        background: #4dcfe0;
      }
      &:visited {
        background: #4dcfe0;
      }
    }
    .is-first-item {
      border-left: none;
      border-right: none;
    }
    .is-last-item {
      border-left: none;
      border-right: none;
      border-bottom: none;
    }
  }
  .current {
    display: none;
  }
}
.msg-counter {
  color: #4dcfe0;
  font-weight: bold;
  background: #fff;
  padding: 3px 5px;
  min-width: 11px;
  display: table;
  text-align: center;
  position: absolute;
  right: 10px;
  top: 10px;
  @include bradius(10px);
  &__item {
    vertical-align: middle;
    display: table-cell;
  }
}

.header__exdor {
  .row {
    padding-top: 116px;
    h1 {
      margin-left: 308px;
      display: inline-block;
    }
    .header__exdor--show {
      width: 100px;
      height: 100px;
      display: inline-block;
      vertical-align: top;
      margin-left: 145px;
      margin-top: 7px;
      position: relative;
      z-index: 200;
      &:before {
        content: '';
        width: 686px;
        height: 432px;
        background: url("../img/header__main--bg-icon.png") no-repeat;
        position: absolute;
        z-index: 10;
        visibility: visible;
        top: -165px;
        left: -290px;
      }
      .header__exdor--play {
        width: 100px;
        height: 101px;
        display: block;
        position: relative;
        z-index: 30;
        background: #83ffff;
        color: #03b7d3;
        font-size: 50px;
        text-align: center;
        @include bradius(50px);
        i{
          margin: 25px 0 0 14px;
        }
        &:hover {
          background: #4dcfe0;
          color: #17ffff;
        }
        &:focus {
          color: #03b7d3;
          background: #b1eaf1;
        }
      }
    }
  }
  .exdor-presentation {
    color: #005f63;
    font-size: 12px;
    margin-left: 310px;
    margin-top: 15px;
    display: inline-block;
    padding: 10px 0 1px 0;
    text-decoration: none;
    border-bottom: 1px solid rgba(255, 255, 255, 0);
    &:hover {
      border-bottom: 1px solid #005f63;
    }
    &:focus {
      border-bottom: 1px solid #005f63;
    }
  }
}

.main-page {
  .header__promo-space {
    position: absolute;
    top: 20px;
    right: 0;
  }
}
.header__promo-space {
  position: relative;
  z-index: 300;
  width: 180px;
  height: 270px;
  overflow: hidden;
  @include bradius(4px);
  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #808080;
  }
  .promo-space__bg {
    position: absolute;
    z-index: 15;
  }
  .promo-space__cover {
    position: relative;
    z-index: 20;
    text-align: center;
    img {
      margin-top: 93px;
    }
    div {
      color: #fff;
      font-size: 12px;
      margin-top: 32px;
      line-height: 1.5;
    }
  }
  .promo-space__more {
    width: 90%;
    padding: 0;
    position: absolute;
    z-index: 20;
    bottom: 10px;
    left: 5%;
  }
  .grey-btn {
    color: #fff;
    font-size: 12px;
    text-decoration: none;
    height: 30px;
    line-height: 2.5;
    text-align: center;
    @include bradius(4px);
  }
}

/* Регистрация main page */
.registration {
  color: #333333;
  padding: 30px 0;
  width: 100%;
  background: url('../img/registration--bg.jpg') repeat;
}
.user-reg__wrap {
  width: 780px;
  height: 70px;
  margin: 0 auto 35px auto;
  background: url('../img/user-reg--bg.jpg') repeat-x;
  box-shadow: 0px 1px 1px 0px rgba(51, 51, 51, 0.17)
}
.user-reg__form {
  padding: 20px;
  .user-reg__title {
    margin: 9px 0 0 7px;
    display: inline-block;
  }
  .user-reg__inputs {
    float: right;
  }
  input , select {
    height: 30px;
    position: relative;
    font-size: 12px;
    @include bradius(4px);
  }
  input[type="tel"] ,
  select {
    margin-left: 18px;
  }
  .is-over-submit {
    float: right;
    margin-left: 20px;
  }
  .user-reg__city--wrap {
    display: inline-block;
    position: relative;
    cursor: pointer;
    i {
      color: #fe9f0f;
      position: absolute;
      z-index: 10;
      right: 8px;
      top: 8px;
    }
  }
  #reg-tmp-select {
    display : none;
  }
  .user-reg__city {
    padding: 0 0 0 5px;
    //padding: 0 7px 0 5px;
    width: 100px;
    color: #333;
    margin-left: 0;
    border: 1px solid #fe9f0f;
    cursor: pointer;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background: #fff url('../img/select__arrow-or.png') right 50% no-repeat;
    option {
      color: #333;
    }
    &::-webkit-input-placeholder {
      color: #333;
    }
    &:-moz-placeholder {
      color: #333;
    }
    &::-moz-placeholder {
      color: #333;
    }
    &:-ms-input-placeholder  {
      color: #333;
    }
  }
  @media screen and (min-width:0\0) {
    .user-reg__city {
      padding: 0 7px 0 5px;
    }
  }
  @-moz-document url-prefix() {
    .user-reg__city {
      padding: 0 7px 0 5px;
    }
  }
  .user-reg__phone-num {
    width: 227px;
    padding: 0 10px;
    border: 1px solid #fe9f0f;
    &::-webkit-input-placeholder {
      color: rgba(51, 51, 51, 0.5);
    }
    &:-moz-placeholder {
      color: rgba(51, 51, 51, 0.5);
    }
    &::-moz-placeholder {
      color: rgba(51, 51, 51, 0.5);
    }
    &:-ms-input-placeholder  {
      color: rgba(51, 51, 51, 0.5);
    }
  }
  .user-reg__submit {
    padding: 0;
    width: 160px;
    border: none;
  }
}
/* appearance: none для ie10+ */
.user-reg__city::-ms-expand , .header__user-panel .user-city::-ms-expand ,.my-companion__select::-ms-expand , select::-ms-expand {
  display: none;
}
.user-reg__benefits {
  width: 790px;
  margin: 0 auto;
  h2 {
    text-align: center;
    color: #333333;
    font-size: 17px;
    margin-bottom: 15px;
  }
  .benefits__item {
    padding: 10px 17px;
    width: 160px;
    display: inline-block;
    text-align: center;
    border-right: 1px solid rgba(51, 51, 51, 0.1);
    &:last-child {
      border-right: none;
    }
    .far, .fas {
      display: block;
      font-size: 40px;
      margin-bottom: 15px;
    }
  }
}
.send-code {
  &__block {
    width: 330px;
    margin: 0 auto;
  }
  &__input {
    height: 30px;
    border: 1px solid #e5e5e5;
    color: #333;
    padding: 0 8px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background: #fff;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    -moz-border-radius: 4px;
    -webkit-border-radius: 4px;
    -ms-border-radius: 4px;
    border-radius: 4px;
  }
  &__input ,
  &__submit {
    margin-bottom: 20px;
    width: 160px;
  }
  &__line-label {
    margin-right: 6px;
  }
}

/* Блок "Ещё больше возможностей" main page */
.opportunities {
  padding: 46px 0 70px 0;
  h2 {
    text-align: center;
    color: #333333;
    font-size: 17px;
    margin-bottom: 24px;
  }
  .opportunities__slider-cover {
    width: 804px;
    position: relative;
    margin: 0 auto;
  }
  .frame {
    height: 123px;
    line-height: 1;
    ul {
      li {
        width: 200px;
        background: none;
      }
    }
  }
}

/* Footer main page */
.main-page-footer {
  position: absolute;
  bottom: 0;
}
.main-page-footer ,
.info-page-footer {
  left: 0;
  height: 50px;
  width: 100%;
  background: #02abc0;
  color: #fff;
  .container {
    &:after {
      display: none;
    }
  }
  .footer__copyright {
    line-height: 50px;
    width: 192px;
    display: inline-block;
    margin-left: 9px;
  }
  .footer__project-about {
    line-height: 50px;
    //width: 60%;
    display: inline-block;
    margin-left: -20px;
    a {
      padding: 18px 19px;
      margin: 0;
      border: 0;
      color: #fff;
      text-decoration: none;
      &:hover {
        background: #0296a6;
      }
    }
    .active {
      background: #0296a6;
    }
  }
  .footer__switch-lang {
    padding: 18px 19px;
    margin: 0;
    border: 0;
    color: #fff;
    text-decoration: none;
    float: right;
    &:hover {
      background: #0296a6;
    }
  }
  .footer__switch-lang--active {
    background: #0296a6;
  }
}



/* My page - empty */
.header__bar {
  width: 100%;
  height: 50px;
  background: #02abc0;
}
.header__logo--wrap {
  padding: 10px 0;
}
.header__widget {
  float: right;
  /*   padding: 15px 0 0 0; */
  .widget__exchange-value ,
  .widget__convert ,
  .widget__calc ,
  .header__sign-out {
    display: inline-block;
    color: #fff !important;
    text-decoration: none;
  }
  .widget__exchange-value {
    opacity: 0.75;
    margin: 0 15px 0 20px;
    position: relative;
    &:after {
      content: '';
      background: url('../img/widget__bord.png') right no-repeat;
      position: absolute;
      right: -15px;
      height: 20px;
      width: 1px;
      top: -3px;
    }
    span {
      &:first-child {
        margin-right: 5px;
      }
    }
  }
  .widget__convert {
    padding: 16px 23px 14px 23px;
    position: relative;
    margin-left: -1px;
    &:hover {
      background: #0296a6;
    }
    &:after {
      content: '';
      background: url('../img/widget__bord.png') right no-repeat;
      position: absolute;
      right: 0;
      height: 20px;
      width: 1px;
      top: 13px;
    }
  }
  .widget__convert-icon {
    display: inline-block;
    vertical-align: middle;
    div {
      line-height: 0;
      i {
        font-size: 10px;
        margin-right: 3px;
      }
    }
  }
  .widget__calc {
    padding: 17px 15px 16px 15px;
    position: relative;
    margin-left: -1px;
    &:hover {
      background: #0296a6;
    }
    i {
      margin-right: 10px;
      float: left;
      font-size: 17px;
      margin-top: -2px;
    }
    &:after {
      content: '';
      background: url('../img/widget__bord.png') right no-repeat;
      position: absolute;
      right: 0;
      height: 20px;
      width: 1px;
      top: 13px;
    }
  }
  .header__sign-out {
    padding: 18px 16px;
    margin-left: -1px;
    &:hover {
      background: #0296a6;
    }
  }
}
.widget__active {
  background: #0296a6 !important;
}
.widget__search--wrap {
  margin: 0px 540px 0 200px;
  padding: 10px 0 0;
}
.widget__search {
  padding: 0 10px 0 30px;
  width: 95%;
  height: 30px;
  position: relative;
  font-size: 12px;
  color: #fff;
  background: #015964 url('../img/widget__search-icon.png') left center no-repeat;
  border: none;
  &::-webkit-input-placeholder {
    color: rgba(223, 246, 249, 0.5);
  }
  &:-moz-placeholder {
    color: rgba(223, 246, 249, 0.5);
  }
  &::-moz-placeholder {
    color: rgba(223, 246, 249, 0.5);
  }
  &:-ms-input-placeholder  {
    color: rgba(223, 246, 249, 0.5);
  }
}
.widget__submit {
  display: none;
  position: absolute;
  width: 25px;
  height: 20px;
  top: 15px;
  border: 0;
  left: 0px;
  background: url("../img/search--active.png") left center no-repeat;
}
.header__go-back {
  padding: 17px 0 0 0;
  text-align: left;
  display: inline-block;
  i {
    font-size: 20px;
    margin-right: 10px;
    float: left;
    margin-top: -3px;
  }
}

/* Стили при нажатии на поиск в шапке */
.search-wrap--active {
  margin: 0px 200px 0 200px;
  position: relative;
  .search--active {
    background: #c0eaef;
    color: #333;
    &::-webkit-input-placeholder {
      color: #02abc0;
    }
    &:-moz-placeholder {
      color: #02abc0;
    }
    &::-moz-placeholder {
      color: #02abc0;
    }
    &:-ms-input-placeholder  {
      color: #02abc0;
    }
  }
  .search--active-cls {
    content: "\f00d";
    font-family: 'Font Awesome 5 Free';
    font-style: normal;
    font-weight: 900;
    text-decoration: inherit;
    position: absolute;
    top: 12px;
    right: 7px;
    z-index: 100;
    cursor: pointer;
    font-size: 20px;
    color: #02abc0;
  }
}
.search-hidden {
  display: none !important;
}
.is-hidden {
  display: none !important;
}



/* Внутренняя страница - общие стили */
main {
  margin-top: 20px;
}
.header__logo--wrap ,
.main-features {
  float: left;
  position: relative;
  z-index: 10;
  width: 180px;
  margin-right: 20px;
}
.main-features {
  position: absolute;
}
.main-menu__list {
  position: relative;
  top: 0;
  margin-bottom: 20px;
  box-shadow: 0px 1px 1px 0px rgba(51, 51, 51, 0.17);
}

/* Моя страница empty - Мои партнеры - Общие стили заголовка */
.section-title {
  font-size: 17px;
  color: #333;
  font-weight: normal;
  margin-bottom: 10px;
  display: inline-block;
}
.section-title__sub {
  color: #808080;
  font-size: 12px;
  margin-left: 2px;
}

/* Моя страница empty - Мои партнеры */
.main-partners__block ,
.main-coworkers__block {
  border: 1px dashed #cccccc;
  padding: 0 9px;
  text-align: center;
}
.main-partners__find ,
.main-coworkers__find {
  color: #0296a6;
  padding: 4px 0 5px 0;
  display: block;
  i {
    font-size: 17px;
    margin: 0 4px;
  }
}
.main-partners__list ,
.main-coworkers__list {
  padding: 9px 0 0 0;
  text-align: left;
}
.partners-list__item ,
.coworkers-list__item {
  display: inline-block;
  text-align: left;
  margin-bottom: 14px;
  &:nth-child(odd){
    margin-right: 6px;
  }
}
.partner-info__link ,
.coworker-info__link {
  display: block;
  position: relative;
  text-align: center;
  .partner-info__name ,
  .coworker-info__name {
    display: inline-block;
    margin-top: 5px;
    padding: 0 0 1px 0;
    border-bottom: 1px solid transparent;
    color: #333333;
  }
  &:hover {
    .partner-info__name ,
    .coworker-info__name {
      border-bottom: 1px solid #333333;
    }
  }
}
.partner-info__photo ,
.coworker-info__photo {
  width: 75px;
  height: 75px;
  overflow: hidden;
  position: relative;
  img {
    position: absolute;
    width: 75px;
    height: 75px;
    top: 0;
    left: 0;
  }
}

/* Моя компания - Заявка сотрудника в левом сайдбаре */
.new-coworker {
  padding: 10px;
  background: #fff;
  border: 1px solid #ff6e09;
}
.new-coworker__link {
  display: block;
  position: relative;
  .new-coworker__photo {
    width: 75px;
    height: 75px;
    float: left;
    overflow: hidden;
    position: relative;
    img {
      position: absolute;
      width: auto;
      height: auto;
      top: 0;
      left: 0;
    }
  }
  .new-coworker__name--cover {
    position: absolute;
    top: 10px;
    right: 0;
    width: 75px;
  }
  .new-coworker__name {
    border-bottom: 1px solid transparent;
    color: #02abc0;
    line-height: 1.5;
    display: inline;
  }
  &:hover {
    .new-coworker__name {
      border-bottom: 1px solid #0296a6;
      color: #0296a6;
    }
  }
  &:focus {
    .new-coworker__name {
      border-bottom: 1px solid #02abc0;
      color: #02abc0;
    }
  }
}
.new-coworker__descr {
  line-height: 1.6;
  margin: 7px 0 20px;
}
.new-coworker__cancel ,
.submit--rcover {
  float: right;
}



/* Внутренняя страница - Дополнительные функции в правом сайдбаре */
.additional-features {
  width: 180px;
  margin-left: 20px;
  float: right;
  position: absolute;
  right: 0;
  &--inner {
    position: relative;
  }
}
.additional-features-wide {
  width: 380px;
  margin-left: 20px;
  float: right;
}
.personal-form {
  padding: 8px 10px;
  background: #b1eaf1;
  box-shadow: 0px 1px 1px 0px rgba(1, 130, 142, 0.5);
  h2 {
    color: #333;
    font-size: 12px;
    font-weight: 400;
    margin: 4px 0 18px 2px;
  }
}
.personal-form__step {
  .personal-form__title {
    font-weight: bold;
    margin-bottom: 10px;
  }
  .personal-form__input {
    padding: 8px 10px;
    background: #fff;
    border: 1px solid #ccc;
    width: 100%;
    margin-bottom: 10px;
    @include bsizing;
    &::-webkit-input-placeholder {
      color: #999;
    }
    &:-moz-placeholder {
      color: #999;
    }
    &::-moz-placeholder {
      color: #999;
    }
    &:-ms-input-placeholder  {
      color: #999;
    }
  }
  .personal-form__submit {
    padding: 8px 10px 8px 35px;
    text-align: center;
    color: #005f63;
    background: url('../img/personal-form__submit-icon.png') left center no-repeat;
    border: none;
    @include bsizing;
  }
  .personal-form__close {
    color: rgba(51, 51, 51, 0.5);
    text-decoration: none;
    float: right;
    padding: 8px 0 0 0;
    border-bottom: 1px solid transparent;
    &:hover {
      border-bottom: 1px solid rgba(51, 51, 51, 0.8);
      color: rgba(51, 51, 51, 0.8);
    }
  }
}

/* Внутренняя страница - Центральный блок */
.page-content {
  margin: 0 200px 0 200px;
  position: relative;
  border-top: 1px solid transparent
}
.page-content-wide ,
.page-content-form {
  margin: 0 0 0 200px;
  position: relative;
}
.page-content-form__right {
  display: inline-block;
  vertical-align: top;
  width: 180px;
  margin-left: 15px;
}
.page-content-form__left {
  display: inline-block;
  vertical-align: top;
}
.page-content-form__wrap {
  min-height: 100%;
  height: auto !important;
  height: 100%;
  position: relative;
  .left-200 {
    .page-content-form__left {
      width: calc(100% - 200px);
    }
  }
  .left-400 {
    .page-content-form__left {
      width: calc(100% - 400px);
    }
  }
  .full-request {
    .page-content-form__right {
      width: 380px;
      margin-right: 0;
    }
  }
}

.is-mright-400 {
  margin-right: 400px;
}
.is-mright-200 {
  margin-right: 200px;
}
.page-content-masonry {
  margin: 0 190px 0 190px;
  position: relative;
}
.section-user-info {
  background: #fff;
  min-height: 180px;
  position: relative;
  overflow: hidden;
}

/* Внутренняя страница - Центральный блок - Профиль */
#choose-portrait-img ,
#choose-eq-img {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}
.user-portrait {
  float: left;
  width: 160px;
  height: 160px;
  position: relative;
  overflow: hidden;
  padding: 10px 11px 10px 10px;
  border-right: 1px solid #fff0e6;
}
.user-portrait__img {

  background: #b1eaf1;

  &--image_exists {
    background: none !important;
  }

  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  img {
    width: auto;
    height: auto;
  }
}
.user-portrait__space {
  position: relative;
  width: 100%;
  height: 100%;
  display: table;
  background: #fef7e1;
  border: 1px solid #feebb3;
}
.user-portrait__helpers {
  display: table;
  color: #fe8e0d;
  position: absolute;
  width: 90%;
  height: 90%;
  top: 5%;
  left: 5%;
  z-index: 100;
  text-align: center;
  &:hover {
    cursor: pointer;
  }
}
.helpers-signs__content {
  display: table-cell;
  vertical-align: middle;
}
.helpers-signs__icons {
  margin-bottom: 10px;
  .fa-plus {
    font-size: 12px;
    margin-right: 3px;
  }
  .fa-camera {
    font-size: 20px;
  }
}
.user-profile {
  margin-left: 180px;
  padding: 18px 21px;
  height: 140px;
  position: relative;
}
.user-profile__empty-contact,
.user-profile__contact {
  font-weight: bold;
}
.user-profile__contact {
  font-weight: 700;
  margin-bottom: 5px;
}
.user-profile__empty-text ,
.user-profile__text{
  margin-top: 15px;
  p {
    margin-bottom: 8px;
  }
}

.user-profile__status {
  position: absolute;
  bottom: 15px;
  left: 21px;
}
.profile-ind {
  float: left;
}
.profile-descr {
  color: #333;
  margin-left: 100px;
  overflow: hidden;
  display: block;
}

/* Кнопка "Заполнить анкету" */
.user-actions {
  position: absolute;
  right: 0;
  top: 10px;
}
.user-actions__rating-level {
  position: absolute;
  width: 70px;
  height: 13px;
  right: 9px;
  top: 8px;
  i {
    float: left;
  }
}
.rate__lvl {
  height: 13px;
  margin-right: 30px;
  overflow: hidden;
  background: url('../img/rate__lvl.png') no-repeat;
  &--5 {
    background-position-x: 0;
  }
  &--4 {
    background-position-y: -14px;
  }
  &--3 {
    background-position-y: -28px;
  }
  &--2 {
    background-position-y: -42px;
  }
  &--1 {
    background-position-y: -56px;
  }
  &--no-edit {
    margin-right: 0;
  }
}
.user-actions__add-form {
  background: #fef7e1;
  color: #fe8e0d;
  padding: 0 10px;
  height: 30px;
  display: inline-block;
  line-height: 30px;
  i {
    margin-left: 7px;
    font-size: 20px;
    vertical-align: sub;
  }
}

/* Кнопка "Редактировать" */
.slide-out__edit {
  position: absolute;
  left: -130px;
  line-height: 30px;
  background: #dff6f9;
  color: #03bbd3;
  padding: 0 30px 0 10px;
  width: 120px;
  height: 30px;
  line-height: 30px;
  i {
    margin-right: 7px;
    font-size: 20px;
    vertical-align: sub;
  }
  &:focus {
    background: #b1eaf1;
  }
  &--has-lvl {
    left: -35px;
  }
}


/* Внутренняя страница - Центральный блок - Профиль компании */
.section-company-profile {
  background: #fff;
  padding: 10px;
  position: relative;
  overflow: hidden;
}
.company-profile__block {
  margin-bottom: 10px;
}
.company-profile__logo {
  margin: 0 20px 0 0;
  float: left;
  overflow: hidden;
}
.company-profile__name {
  font-weight: bold;
  margin-right: 120px;
  line-height: 1.7;
}
.company-profile__rating-level {
  margin-top: 4px;
  width: 65px;
  display: inline-block;
  margin-right: 0;
}
.company-card {
  text-align: left;
  padding: 12px 10px;
  margin: 0 -10px;
  border-top: 1px solid #e5e5e5;
  border-bottom: 1px solid #e5e5e5;
}
.company-card__item {
  display: inline-block;
  vertical-align: bottom;
  width: 23%;
  border-right: 1px solid #eaeaea;
  margin-left: 2%;
  &:first-child {
    margin-left: 0;
  }
  &:last-child {
    border: none;
  }
}
.company-profile__about {
  margin-top: 9px;
  position: relative;
}
.main-tagline__text {
  margin-right: 70px;
}
.main-tagline__style ,
.outbox-msg__style {
  margin-right: 0;
  p ,
  ul ,
  img {
    line-height: 1.6;
    margin-bottom: 10px;
  }
}
.main-tagline__full {
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.outbox-msg__full {
  display: none;
}
.main-tagline__open {
  position: absolute;
  right: 0;
  bottom: 0;
}

/* Кнопки "создать новость/объявление/заявку" */
.create__list {
  list-style: none;
  width: 100%;
  text-align: left;
  a {
    color: #fe9f0f;
  }
}
.create__link {
  display: inline-block;
  margin-right: 20px;
  &:last-child {
    margin-right: 0;
  }
  a {
    width: 180px;
    height: 30px;
    line-height: 30px;
    text-align: center;
    display: block;
    background: rgba(254, 228, 128, 0.25);
    .fa-plus {
      font-size: 12px;
      margin-right: 3px;
    }
    .fa-newspaper-o ,
    .fa-bullhorn ,
    .fa-list-alt {
      font-size: 20px;
      margin-right: 5px;
      vertical-align: sub;
    }
  }
}

/* Страница профиля - Добавить/добавлен в партнеры */
.partner-status {
  height: 50px;
  line-height: 50px;
  padding: 0 15px;
  position: relative;
  border-bottom: 1px solid #d9dcdd;
}
.partner-status__lbar {
  display: inline-block;
}
.partner-status__rbar {
  float: right;
}
.partner-status__msg {
  background: #fff;
  width: 280px;
  position: absolute;
  z-index: 1000;
  left: -60px;
  top: 40px;
  padding: 10px;
  line-height: 1.6;
  .news-advpost__form {
    background: transparent !important;
    padding: 0;
    margin-top: 15px;
  }
  .reply__form-box {
    margin-left: 0 !important;
  }
  .status-msg__cancel ,
  .status-msg__del {
    margin: 10px 0 5px 0;
  }
  &::before ,
  &::after {
    content: '';
    position: absolute;
    background: #fff;
    left: 50%;
    top: -4px;
    margin-left: -7px;
    width: 10px;
    height: 10px;
    box-shadow: -1px -1px 1px 0px rgba(51, 51, 51, 0.1);
    z-index: -1;
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
  }
  &::before {
    z-index: 1; /* Накладываем поверх, чтобы скрыть следы тени */
    box-shadow: none; /* Прячем тень */
  }
}
.rbar__link {
  position: relative;
  margin-right: 40px;
  &:after {
    position: absolute;
    top: -3px;
    right: -19px;
    content: '';
    background: rgba(51, 51, 51, 0.75);
    width: 1px;
    height: 20px;
    cursor: default;
  }
}

/* Моя страница - Заявки */
.main-requests {
  h2 ,
  .section-title__helpers {
    display: inline-block;
  }
  h2 {
    max-width: 50%;
    @extend .is-long-row;
  }
}
.section-title__helpers {
  float: right;
  a {
    padding: 0px 10px;
    height: 20px;
    line-height: 23px;
    display: inline-block;
  }
}
.new-req-items {
  .req-item {
    &:last-child {
      border-bottom: 1px solid #bdccd4;
    }
    &.is-last-item {
      border-bottom: none;
    }
  }
}
.req-item {
  position: relative;
  border-bottom: 1px solid #bdccd4;
  background: #fff;
  @include tranz;
  &:last-child {
    border-bottom: none;
  }
  p {
    color: #333;
    @extend .is-long-row;
    margin-bottom: 6px;
    &:last-child {
      margin-bottom: 0;
    }
  }
  &:hover {
    cursor: pointer;
    background: #feebb3;
  }
  &.is-last-item {
    border-bottom: none;
  }
}
.request__item--wrap {
  padding: 7px 10px;
  display: block;
}
.req-item__descr {
  color: #666;
}
.request__status--active {
  border-left: #ff6c08;
  border-left-width: 5px;
  border-left-style: solid;
  .req-item__status {
    color: #ff6c08;
  }
}
.request__status--done {
  border-left: #00bea5;
  border-left-width: 5px;
  border-left-style: solid;
  .req-item__status {
    color: #00bea5;
  }
}
.request__status--canceled {
  border-left: #bdccd4;
  border-left-width: 5px;
  border-left-style: solid;
  .req-item__status {
    color: #bdccd4;
  }
}
.request__status--answered {
  border-left: #03b7d3;
  border-left-width: 5px;
  border-left-style: solid;
  .req-item__status {
    color: #03b7d3;
  }
}
.request-compare__print-button {
  position: absolute;
  top: 12px;
  right: 100px;
}
.request-positions__print-button {
  position: absolute;
  top: 12px;
  right: 0px;
}
.req-item__helpers {
  color: #adadad;
  text-align: right;
  float: right;
  position: absolute;
  right: 1px;
  top: 1px;
  width: 28px;
  height: 24px;
  cursor: pointer;
  @include tranz;
  background: #fff url('../img/req-item__helpers.png') top center no-repeat;
  background-position-y: 0;
  -moz-border-radius: 0 4px;
  -webkit-border-radius: 0 4px;
  -ms-border-radius: 0 4px;
  border-radius: 0 4px;
  i {
    padding: 6px 8px;
    color: #adadad;
  }
  &:hover {
    background: #f7fdfe url('../img/req-item__helpers.png') top center no-repeat;
  }
  &--active {
    background: #f7fdfe url('../img/req-item__helpers.png') center -24px no-repeat !important;
  }
}
.req-item__actions {
  display: none;
  font-size: 12px;
  color: #adadad;
  background: #fff;
  position: absolute;
  top: 25px;
  right: 0px;
  z-index: 700;
  border: 1px solid #fff;
  li {
    border-bottom: 1px solid #e5e5e5;
    padding: 12px 10px;
    text-align: left;
    display: block;
    color: #333;
    white-space: nowrap;
    cursor: pointer;
    @include tranz;
    &:last-child {
      border-bottom: none;
    }
    &:hover {
      background: #effafc;
    }
    a {
      color: #333;
    }
  }
  &--active {
    background: #f2f2f2;
  }
}
.req-item__time {
  color: #999;
  position: absolute;
  bottom: 10px;
  right: 10px;
  text-align: right;
  white-space: nowrap;
}
.req-item__manager {
  color: #a3ced5;
  margin-right: 10px;
  border-bottom: 1px solid transparent;
  position: relative;
  z-index: 10;
  &:hover {
    border-bottom: 1px solid #a3ced5;
  }
  span {
    color: #999;
  }
}
/* Моя страница - Новости */
.post-wrapper {
  display: none;
  width: 780px;
  margin: 0 auto;
}
.fancybox-inner {
  overflow: visible !important;
}
.post-wrapper {
  .advpost__content {
    width: 780px;
  }
  .advpost__item {
    display: block;
  }
  .frame {
    overflow: visible;
    height: 100%;
    ul {
      transform: none !important;
      width: 780px !important;
      li {
        width: 760px;
        float: none;
        margin: 0 10px 20px 10px;
        overflow: hidden;
        text-align: center;
        img {
          cursor: default;
          display: block;
          margin: 0 auto;
          width: 100%;
        }
      }
    }
  }
  .news-advpost__text ,
  .advpost__text {
    cursor: auto;
  }
  .news-advpost-head__date {
    span {
      b {
        border-bottom: none !important;
      }
    }
    &:hover {
      span {
        border-bottom: none !important;
        b {
          border-bottom: none !important;
        }
      }
    }
  }
}

.news-advpost {
  position: relative;
  h2 {
    display: inline-block;
    i {
      display: inline-block;
      vertical-align: top;
      font-size: 12px;
    }
  }
}
.news-advpost__head ,
.advpost__head {
  padding: 10px;
  position: relative;
  .req-item__helpers {
    z-index: 600;
  }
}
.news-advpost__head ,
.news-advpost__text ,
.advpost__head ,
.advpost__text ,
.reply {
  a {
    position: relative;
    z-index: 500;
  }
}
.lower-layer {
  position: absolute !important;
  z-index: 1 !important;
  padding: 0 !important;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}
.news-advpost-head__photo ,
.news-advpost__exdor-logo ,
.reply__image ,
.reply__form-image ,
.my-dialogs__image  {
  float: left;
  width: 60px;
  height: 60px;
  overflow: hidden;
  position: relative;
  img {
    top: 0;
    left: 0;
  }
}
.news-advpost-head__photo {
  background: #b1eaf1 url('../img/news-advpost-head__photo--empty.jpg') center no-repeat;
}
.news-advpost__company-logo {
  border: 1px solid #ccc;
  float: left;
  width: 60px;
  height: 60px;
  overflow: hidden;
  position: relative;
  img {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    margin: auto;
    max-width: 100%;
    max-height: 100%;
  }
}
.news-advpost-head__name {
  margin-bottom: 3px;
  display: inline-block;
}
.news-advpost-head__comname {
  margin-bottom: -3px;
  display: inline-block;
}
.news-advpost-head__descr {
  overflow: hidden;
  margin-left: 70px;
  padding: 9px 0;
  line-height: 1.7;
}
.news-advpost-head__date {
  display: block;
  color: #808080;
  @include tranz;
  span {
    position: relative;
    z-index: 1;
    display: inline;
    color: #808080;
    @include tranz;
    i {
      color: #808080;
      float: left;
      font-size: 20px;
      margin-right: 6px;
      border-bottom: 1px solid transparent;
    }
    b {
      @include tranz;
      border-bottom: 1px solid transparent;
      font-weight: normal;
    }
    &:hover {
      i {
        border-bottom: 1px solid transparent;
      }
      b {
        border-bottom: 1px solid #808080;
      }
    }
  }
}
.news-advpost__text {
  padding: 0 10px 22px 10px;
  line-height: 1.7;
  position: relative;
  p {
    margin-bottom: 10px;
    &:last-child {
      margin-bottom: 0;
    }
  }
  a {
    line-height: 1;
    display: inline-block;
    padding: 8px 0 1px 0;
  }
}
.news-advpost__full {
  width: 760px;
  display: none;
}
.advpost__slider-class {
  position: relative;
  z-index: 500;
  margin-bottom: 10px;
}
.news-advpost__big-img {
  margin: 0 10px 20px 10px;
  overflow: hidden;
  text-align: center;
  img {
    display: block;
    margin: 0 auto;
    width: 100%;
  }
}
.news-advpost__feedback {
  padding: 0 10px 10px 10px;
  .feedback__comments {
    display: inline-block;
    i {
      //font-size: 20px;
      //margin-right: 5px;
      margin-right: 2px;
    }
  }
  .feedback__postlike {
    cursor: pointer;
    color: #999;
    float: right;
    position: relative;

    i {
      font-size: 15px;
      margin-left: 7px;
      float: right;
    }
    .fa-heart {
      color: #02abc0;
    }
    &:hover {
      color: #02abc0;
    }
  }
}
.hidden-like {
  display: none;
}
/* Моя страница - Объявление */
.advpost {
  background: #e6e9e9;
  padding: 20px 0;
  position: relative;
  overflow: hidden;
  &--lite {
    .advpost__icon ,
    .advpost__actions {
      opacity: 0.5;
    }
  }
  &--type_label {
    width: 200px;
    background: #4dcfe0;
    position: absolute;
    text-align: center;
    line-height: 30px;
    color: #fff;
    letter-spacing: 2px;
    text-transform: uppercase;
    top: auto;
    right: -65px;
    z-index: 10;
    bottom: 20px;
    left: auto;
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
  }
}
.advpost_bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  &:before ,
  &:after {
    font-family: 'Font Awesome 5 Free';
    font-style: normal;
    font-weight: 900;
    text-decoration: inherit;
    color: rgba(51,51,51,0.1);
    font-size: 50px;
    position: absolute;
    top: 82px;
  }
  &:before {
    content: "\f10d";
    left: 70px;
  }
  &:after {
    content: "\f10e";
    right: 70px;
  }
  img {
    -webkit-filter: blur(10px);
    filter: blur(10px);
    filter:progid:DXImageTransform.Microsoft.Blur(PixelRadius='10');
    -ms-filter: progid:DXImageTransform.Microsoft.Blur(PixelRadius='10');
    position: relative;
    z-index: 1;
    width: 100%;
    height: 100%;
  }
  .ie-cover {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 5;
  }
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .ie-cover {
    background: #000;
    opacity: 0.5;
  }
}
.advpost__actions {
  position: absolute;
  right: 0;
  top: 16px;
  z-index: 15;
}
.advpost__icon {
  height: 30px;
  line-height: 30px;
  color: #fff;
  background: rgba(51, 51, 51, 0.25);
  top: 15px;
  padding: 0 10px;
  border-radius: 0 3px 3px 0;
  position: absolute;
  z-index: 5;
  left: 0px;
  i {
    font-size: 16px;
    margin-right: 3px;
  }
}
.advpost__icon--opacity {
  opacity: .5;
}
.slide-out {
  i {
    position: relative;
    z-index: 5;
    color: #fff;
    font-size: 20px;
    float: right;
    margin: 5px 12px 0 0;
  }
}
.slide-out__fix {
  position: absolute;
  left: -10px;
  line-height: 30px;
  background: rgba(51, 51, 51, 0.25);
  color: #fff;
  padding: 0 30px 0 10px;
  width: 120px;
  height: 30px;
  line-height: 30px;
  span {
    opacity: 0;
  }
}
.slide-out__fix--pin {
  left: -85px !important;
  width: 100px;
  i {
    color: #333;
  }
  span {
    opacity: 1 !important;
  }
}
.advpost__content {
  position: relative;
  width: 380px;
  margin: 0 auto;
  background: #fff;
  z-index: 10;
  @include bsizing;
}
.advpost__head {
  p {
    margin-bottom: 3px;
    &:last-child {
      margin-bottom: 0;
    }
  }
}
#advpost__slider {
  margin: 10px 0 5px 0;
}
.advpost__text {
  padding: 0 10px 10px 10px;
  line-height: 1.7;
  cursor: pointer;
  p {
    margin: 5px 0;
  }
}
.news-post__wrapper ,
.adv-post__wrapper {
  position: relative;
}
.advpost__descr {
  display: table;
  width: 100%;
  position: relative;
  z-index: 10;
}
.advpost__footer-l ,
.advpost__footer-r {
  border: 1px solid #fff;
  width: 50%;
  display: table-cell;
  a {
    cursor: pointer;
  }
}
.advpost__footer-l {
  background: #f1f1f1;
  padding: 10px 0 10px 20px;
  color: #ccc;
  -moz-border-radius: 0 0 0 4px;
  -webkit-border-radius: 0 0 0 4px;
  -ms-border-radius: 0 0 0 4px;
  border-radius: 0 0 0 4px;
}
.advpost__footer-r {
  padding: 10px 0 10px 20px;
  -moz-border-radius: 0 0 4px 0;
  -webkit-border-radius: 0 0 4px 0;
  -ms-border-radius: 0 0 4px 0;
  border-radius: 0 0 4px 0;
}
.advpost__footer-my {
  background: #fff6dc;
  color: #fec315;
}
.advpost__footer-author {
  cursor: pointer;
  background: #d9f5f8;
  color: #02abc0;
  .is-blue-text {
    span {
      border-bottom: 1px solid transparent;
      @include tranz;
    }
  }
  &:hover {
    .is-blue-text {
      span {
        border-bottom: 1px solid #02abc0;
      }
    }
  }
}

/* Моя страница - подгрузка */
.load-more {
  margin: 50px auto;
  height: 50px;
  width: 160px;
  position: relative;
  text-align: right;
  span {
    position: absolute;
    right: 0;
    top: 22px;
    color: #bdccd4;
  }
}
.cssload-container{
  position: absolute;
  left: 25px;
  top: 25px;
}
.cssload-whirlpool,
.cssload-whirlpool::before,
.cssload-whirlpool::after {
  position: absolute;
  top: 50%;
  left: 50%;
  border: 1px solid rgba(217,226,230,0.98);
  border-left-color: rgb(190,205,213);
  border-radius: 974px;
  -o-border-radius: 974px;
  -ms-border-radius: 974px;
  -webkit-border-radius: 974px;
  -moz-border-radius: 974px;
}
.cssload-whirlpool {
  margin: -24px 0 0 -24px;
  height: 49px;
  width: 49px;
  animation: cssload-rotate 1150ms linear infinite;
  -o-animation: cssload-rotate 1150ms linear infinite;
  -ms-animation: cssload-rotate 1150ms linear infinite;
  -webkit-animation: cssload-rotate 1150ms linear infinite;
  -moz-animation: cssload-rotate 1150ms linear infinite;
}
.cssload-whirlpool::before {
  content: "";
  margin: -22px 0 0 -22px;
  height: 43px;
  width: 43px;
  animation: cssload-rotate 1150ms linear infinite;
  -o-animation: cssload-rotate 1150ms linear infinite;
  -ms-animation: cssload-rotate 1150ms linear infinite;
  -webkit-animation: cssload-rotate 1150ms linear infinite;
  -moz-animation: cssload-rotate 1150ms linear infinite;
}
.cssload-whirlpool::after {
  content: "";
  margin: -28px 0 0 -28px;
  height: 55px;
  width: 55px;
  animation: cssload-rotate 2300ms linear infinite;
  -o-animation: cssload-rotate 2300ms linear infinite;
  -ms-animation: cssload-rotate 2300ms linear infinite;
  -webkit-animation: cssload-rotate 2300ms linear infinite;
  -moz-animation: cssload-rotate 2300ms linear infinite;
}
@keyframes cssload-rotate {
  100% {
    transform: rotate(360deg);
  }
}
@-o-keyframes cssload-rotate {
  100% {
    -o-transform: rotate(360deg);
  }
}
@-ms-keyframes cssload-rotate {
  100% {
    -ms-transform: rotate(360deg);
  }
}
@-webkit-keyframes cssload-rotate {
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@-moz-keyframes cssload-rotate {
  100% {
    -moz-transform: rotate(360deg);
  }
}

/* Кнопка "Наверх" */
.back-to-top {
  display: none;
  position: fixed;
  bottom: 40px;
}

/* Виджеты в header внутренних страниц */
.widget {
  width: 280px;
  background: #fff;
  position: absolute;
  z-index: 10001;
  right: -1px;
  top: 60px;
}
.widget__head ,
.modal__head {
  background: #fedf83;
  color: #333;
  padding: 13px 10px;
  &--high {
    height: 106px;
    position: relative;
    padding: 13px 10px 7px 20px;
    overflow: hidden;
    img {
      margin: 12px 0;
    }
    span {
      display: block;
    }
  }
  &--blue {
    background: #02abc0;
    color: #fff;
    .modal__close-btn {
      color: #fff;
    }
  }
  &--dark {
    background: transparent;
    color: #fff;
    .modal__close-btn {
      color: #fff;
    }
  }
}

.widget__title ,
.modal__title {
  display: inline-block;
  &--icon {
    font-size: 120px;
    position: absolute;
    right: -10px;
    top: 4px;
    color: #feb212;
  }
}
.widget__close-btn ,
.modal__close-btn {
  float: right;
  display: block;
  color: #333;
  position: relative;
  z-index: 100;
  i {
    float: right;
    margin: -3px 0 0 6px;
    font-size: 20px;
  }
}
.widget__body {
  margin: 8px 10px 10px 10px;
}

/* Калькулятор */
#calculator {
  height: 375px;
  display: none;
}
#calculator__display {
  font-size: 28px;
  margin-right: 5px;
}
.calculator__display--wrap {
  position: relative;
  color: #dff6f9;
  width: 260px;
  height: 67px;
  background-color: #333;
  border: none;
  line-height: 2.3;
  text-align: right;
}
.calculator__history {
  position: absolute;
  bottom: -2px;
  right: 5px;
  font-size: 10px;
}
.calculator__memory__1 {
  position: absolute;
  bottom: -2px;
  left: 5px;
  font-size: 10px;
}
.calculator__memory__2 {
  position: absolute;
  bottom: -2px;
  left: 70px;
  font-size: 10px;
}
.widget__table {
  overflow: hidden;
  tr {
    td {
      width: 56px;
    }
  }
}
.is-display-border {
  border: 2px solid #333;
}
.is-calc-btn {
  width: 100%;
  height: 40px;
  display: block;
  color: #fff;
  font-size: 16px;
  line-height: 40px;
  text-align: center;
  &--d {
    background: #028c9e;
    border: 1px outset #41a9b6;
  }
  &--m {
    background: #0296a6;
    border: 1px outset #41b0bc;
  }
  &--l {
    background: #03bbd3;
    border: 1px outset #72dbe9;
  }
}
.is-calc-res {
  height: 82px !important;
  line-height: 82px !important;
}

/* Конвертер */
#converter {
  display: none;
}
.convert__res-summ ,
.convert__enter-summ ,
.convert__currency {
  color: #fff;
  padding: 0 8px;
}
.convert__currency {
  background: #333 url("../img/convert__bg-c.png") center top repeat-y;
  position: relative;
}
.currency__1st-selected ,
.currency__2nd-selected ,
.rate__wtitle ,
.rate__wnum ,
.enter-summ__wtitle ,
.enter-summ__wnum ,
.res-summ__wtitle ,
.res-summ__wnum {
  display: inline-block;
  width: 49%;
}
.rate__wnum ,
.enter-summ__wnum ,
.res-summ__wnum {
  text-align: center;
}
.currency__1st-selected {
  background: url("../img/convert__currency.png") top left no-repeat;
  background-position-x: 7px;
}
.currency__2nd-selected {
  background: url('../img/convert__currency.png') bottom right no-repeat;
  background-position-x: 7px;
  float: right;
}
.list__1st-selected,
.list__2nd-selected {
  li {
    line-height: 2;
    opacity: .5;
    a {
      color: #fff;
    }
  }
  .active {
    opacity: 1;
  }
}

.list__1st-selected {
  //margin-top: 20px;
}
.list__2nd-selected {
  //margin-bottom: 20px;
  float: right;
  text-align: right;
}
.currency__equal-sign {
  position: absolute;
  top: 50%;
  margin-top: -18px;
  left: 50%;
  margin-left: -18px;
  width: 36px;
  height: 36px;
  background: #fff;
  text-align: center;
  display: table;
  @include bradius(18px);
  i {
    color: #333;
    display: table-cell;
    vertical-align: middle;
  }
}
.convert__rate {
  padding: 0 8px;
  color: #333;
  background: #ccc url('../img/convert__rate.png') center top no-repeat;
  position: relative;
  &:before {
    content: '';
    position: absolute;
    background: url("../img/convert__bg-r.png") center top repeat-y;
    width: 2px;
    height: 100%;
    top: 0;
    left: 129px;
  }
  .rate__wtitle {
    padding: 10px 0;
    li {
      line-height: 2;
      opacity: .5;
      a {
        color: #333;
      }
    }
    .active {
      opacity: 1;
    }
  }
  .rate__wnum {
    padding: 15px 0;
    position: relative;
    float: right;
    text-align: right;
    input {
      width: 100px;
      text-align: center;
      background: transparent;
      border-top: none;
      border-left: none;
      border-right: none;
      border-bottom: 1px solid #333;
      &::-webkit-input-placeholder {
        color: #333;
      }
      &:-moz-placeholder {
        color: #333;
      }
      &::-moz-placeholder {
        color: #333;
      }
      &:-ms-input-placeholder  {
        color: #333;
      }
    }
  }
}
.convert__enter-summ {
  background: #009587 url("../img/convert__bg-s.png") center top repeat-y;;
  .enter-summ__wtitle {
    padding: 15px 0;
  }
  .enter-summ__wnum {
    opacity: .5;
    input {
      width: 100px;
      text-align: center;
      background: transparent;
      border-top: none;
      border-left: none;
      border-right: none;
      border-bottom: 1px solid #fff;
      &::-webkit-input-placeholder {
        color: #fff;
      }
      &:-moz-placeholder {
        color: #fff;
      }
      &::-moz-placeholder {
        color: #fff;
      }
      &:-ms-input-placeholder  {
        color: #fff;
      }
    }
  }
}
.convert__res-summ {
  background: #01828e url("../img/convert__bg-s.png") center top repeat-y;
  .res-summ__wtitle {
    padding: 15px 0;
  }
  .res-summ__wnum {
    opacity: .5;
  }
}

/* Страница новости - подменю */
.sub-menu {
  border-bottom: 1px solid #d9dcdd;
  &--wide {
    width: calc(100% + 180px);
    margin-left: 10px;
  }
  &--wide-n {
    width: calc(100% + 200px);
  }
}
.sub-menu__list {
  margin-bottom: -4px;
  display: inline-block;
  li {
    display: table-cell;
    a {
      color: rgba(2, 150, 166, 0.7);
      border-bottom: 2px solid transparent;
      padding: 12px 10px;
      display: block;
      &:hover {
        color: rgba(2, 150, 166, 1);
        border-bottom: 2px solid rgba(2, 150, 166, 1);
      }
    }
    .active {
      color: rgba(2, 150, 166, 1);
      border-bottom: 2px solid rgba(2, 150, 166, 1);
    }
  }
}
.sub-menu__add-news {
  float: right;
  padding: 0 10px;
  margin-top: 4px;
  display: inline-block;
  overflow: hidden;
  .far, .fas {
    font-size: 10px;
  }
}

/* Страница новости - комментарии */
.reply {
  position: relative;
  padding: 10px;
  min-height: 60px;
  margin-top: -1px;
  border-top: 1px solid #e5e5e5;
  border-bottom: 1px solid #e5e5e5;
    box-sizing: content-box;
}
.reply__content {
  overflow: hidden;
  margin-left: 70px;
  padding: 0;
  line-height: 1.7;
}
.reply__date ,
.my-dialogs__date {
  position: absolute;
  top: 10px;
  right: 10px;
  color: #808080;
}
.news-advpost__form {
  background: #fff;
  padding: 10px;
  &.show-reply {
    background: #dff6f9;
    border: none;
    .reply__form-image {
      display: block;
    }
    .reply__submit--wrap {
      display: inline-block;
    }
    .reply__area {
      display: block;
      height: 60px;
    }
    .reply__form-box {
      margin-left: 70px;
    }
  }
}
.reply__form-image ,
.reply__submit--wrap ,
.reply__area {
  display: none;
}
.reply__form-box ,
.reply-tbox__form-box {
  overflow: hidden;
  padding: 2px 0 0 0;
  line-height: 1.7;
}
.reply__area ,
.reply-tbox__area {
  display: block;
  width: 100%;
  height: 30px;
  min-height: 30px;
  line-height: 1.5;
  outline: none;
  border: 1px solid #e5e5e5;
  padding: 5px 10px;
  margin-bottom: 10px;
  resize: none;
  @include bsizing;
  &::-webkit-input-placeholder {
    color: #e5e5e5;
  }
  &:-moz-placeholder {
    color: #e5e5e5;
  }
  &::-moz-placeholder {
    color: #e5e5e5;
  }
  &:-ms-input-placeholder  {
    color: #e5e5e5;
  }
}
.reply__submit ,
.reply-tbox__submit {
  padding: 8px 10px 8px 35px;
  text-align: center;
  color: #fff;
  background: url("../img/reply__submit.png") left center no-repeat;
  border: none;
  @include bsizing;
}

/* Страница новости - окно Добавить новость */
.modal {
  position: relative;
}
.modal__body {
  padding: 20px 20px 50px 20px;
  /* margin-bottom: 50px; */
}
.modal__footer {
  position: absolute;
  width: 100%;
  bottom: 0;
  left: 0;
}
#add-news ,
#edit-message,
#edit-news-comment,
#edit-news,
#add-equipment ,
#add-advpost ,
#send-mail ,
.modal__block {     // можно заменить все id на modal__block
  width: 580px;
  /* min-height: 290px; */
  background: #fff;
  display: none;
}
.new-msg__modal {
  width: 580px;
  min-height: 290px;
  display: none;
  margin-right: 0 !important;
}
.modal__img {
  width: 600px;
  background: #333333;
}
.modal__content {
  padding: 20px;
  h2 {
    margin: 10px auto 20px auto;
    text-align: center;
    font-weight: normal;
  }
  p {
    line-height: 1.5;
    margin-bottom: 10px;
    &:last-child {
      margin-bottom: 0;
    }
  }
}
.confirm__block {
  width: 45%;
  margin: 20px auto 10px auto;
  position: relative;
  a {
    &:first-child {
      float: left;
    }
    &:last-child {
      float: right;
    }
  }
  &:after {
    content: '.';
    display: block;
    height: 0;
    clear: both;
    visibility: hidden;
  }
}
.add-news__area {
  position: relative;
  display: block;
  width: 100%;
  height: 140px;
  outline: none;
  border: 1px solid #e5e5e5;
  padding: 8px 30px 8px 8px;
  resize: vertical;
  margin-bottom: 5px;
  @include bsizing;
  &::-webkit-input-placeholder {
    color: #ccc;
  }
  &:-moz-placeholder {
    color: #ccc;
  }
  &::-moz-placeholder {
    color: #ccc;
  }
  &:-ms-input-placeholder  {
    color: #ccc;
  }
}
.textarea--pre ,
.solution-descr__choose {
  position: relative;
}
.solution-descr__choose {
  border-bottom: 1px solid #eaeaea;
}
.textarea-count ,
.textarea-count-label {
  font-size: 10px;
  height: 10px;
  z-index: 5000;
  position: absolute;
  right: 10px;
}
.textarea-count {
  top: 10px;
}

#add-news__file ,
#add-reply-tbox__file ,
#add-equipment__file ,
#add-advpost__file {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}
.add-news__label + #add-news__file ,
.add-reply-tbox__label + #add-reply-tbox__file ,
.add-equipment__label + #add-equipment__file ,
.add-advpost__label + #add-advpost__file {
  cursor: pointer;
}
.add-news__label ,
.add-equipment__label ,
.add-advpost__label ,
.add-requests__label {
  display: inline-block;
  padding: 10px 0;
  cursor: pointer;
}
.add-reply-tbox__label {
  display: inline-block;
  padding: 5px 0;
  cursor: pointer;
}
.add-news__submit--wrap ,
.add-equipment__submit--wrap ,
.send-mail__submit--wrap {
  width: 100%;
  text-align: center;
  position: relative;
  display: block;
  border: 1px solid #fff !important;
  @include bsizing;
  i {
    color: #fff;
    font-size: 18px;
    position: absolute; //новый стиль 02.19
    top: 12px; //новый стиль 02.19
    left: 50%; //новый стиль 02.19
    margin-left: -65px; //новый стиль 02.19
  }
}
.add-news__submit ,
.add-equipment__submit ,
.send-mail__submit {
  width: 100%;   //новый стиль 02.19
  padding: 15px 0;
  text-align: center;
  color: #fff;
  border: none;
  @include bsizing;
}

/* Страница Сообщения - блок Мои переписки */
/* Фон пустых диалогов */
.my-dialogs--empty {
  position: relative;
  display: block;
  .no-dialogs {
    text-align: center;
    color: #ccc;
    i {
      font-size: 120px;
      display: block;
      margin: 30px auto 40px auto;
    }
  }
  .fa-long-arrow-up {
    color: #e6e6e6;
    font-size: 120px;
    position: absolute;
    right: 48px;
    top: 0px;
  }
}
/* Диалоги */
.my-dialogs__block ,
.my-companion__block ,
.new-msg__block ,
.my-partners__block ,
.my-partners__rec ,
.requests__col ,
.news-advpost__block ,
.solution-time__block ,
.solution-pay__block ,
.solution-about__block ,
.solution-descr__block ,
.requests-step__block ,
.requests-eq__block ,
.request-lvl__block ,
.requests-step__status ,
.req-addressee__list ,
.requests-step__author ,
.requests-step__executant ,
.requests-comment__block ,
.new-service {
  background: #fff;
}
.my-dialogs__row ,
.my-companion__row ,
.new-msg__row  {
  position: relative;
  padding: 10px;
  display: block;
  margin-top: -1px;
  border-top: 1px solid #e5e5e5;
  border-bottom: 1px solid #e5e5e5;
  &:first-child {
    border-top: none;
  }
  &:last-child {
    border-bottom: none;
  }
  &:hover ,
  &:focus {
    background: #feebb3;
  }
}
.my-companion__image ,
.new-msg__image {
  float: left;
  width: 60px;
  height: 60px;
  overflow: hidden;
  position: relative;
}
.my-dialogs__content {
  height: 60px;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-left: 70px;
  padding: 0;
  line-height: 1.7;
  .my-dialogs__name {
    color: #333;
    font-weight: bold;
  }
  .my-dialogs__text__typing,
  .my-dialogs__text {
    height: 18px;
    color: #808080;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
.my-dialogs__last ,
.my-partners__last ,
.requests__last {
  color: #bdccd4;
  text-align: center;
  margin-top: 40px;
  line-height: 1.7;
}
.my-companion__content {
  height: 60px;
  margin-left: 70px;
  padding: 0;
  display: table;
  .my-companion__name {
    color: #333;
    font-weight: bold;
    display: table-cell;
    vertical-align: middle;
  }
}
.my-companion__select--wrap ,
.submit--cover ,
.my-companion__search {
  display: inline-block;
}
.my-companion__search {
  width: 275px;
  color: rgba(51, 51, 51, 0.5);
  border: 1px solid #ccc;
  padding: 0 10px 0 30px;
  height: 28px;
  position: relative;
  font-size: 12px;
  color: #333;
}
.my-companion__submit {
  background: url("../img/my-companion__search-icon.png") left 50% no-repeat;
  position: absolute;
  z-index: 100;
  width: 30px;
  height: 30px;
  border: none;
}
.my-companion__select {
  height: 30px;
  width: 130px;
  border: 1px solid #ccc;
  cursor: pointer;
  padding: 0 0 0 5px;
  margin-right: 10px;
  color: #333;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: #fff url("../img/select__triangle-blue.png") right 50% no-repeat;
  @include bradius(4px);
}
@media screen and (min-width:0\0) {
  .my-companion__select {
    padding: 0 20px 0 5px;
  }
}
@-moz-document url-prefix() {
  .my-companion__select {
    cursor: pointer;
    padding: 0 8px 0 5px;
  }
}
#tmp-select {
  display: none;
}

/* Блок одного диалога */
.conversation {
  background: #fff;
  position: relative;
  margin-top: 40px;
  padding-top: 10px;
}
.conversation__table {
  opacity: 0.01;
  width: 100%;
  margin-bottom: 105px;
  td {
    vertical-align: top;
    padding: 10px 0 30px 0;
  }
}
.conversation__row {
  cursor: pointer;
  .conversation__action {
    text-align: center;
    width: 40px;
    a {
      font-size: 14px;
      display: none;
      padding: 5px;
      text-align: center;
      vertical-align: middle;
      &:first-child {
        margin-top: 5px;
        border-bottom: 1px solid #ccc;
      }
    }
  }
  &:hover {
    .conversation__action {
      a {
        display: inline-block;
      }
    }
  }
}
.conversation__author {
  width: 70px;
  a {
    float: left;
    width: 60px;
    height: 60px;
    overflow: hidden;
    position: relative;
    img {
      top: 0;
      left: 0;
    }
  }
}
.conversation__author-name {
  font-weight: bold;
  margin: 5px 0;
}
.conversation__date {
  color: #808080;
  text-align: right;
  width: 120px;
}
.conversation__rspace {
  width: 40px;
}
/* Блок одного диалога - ответ */
#conversation__res {
  width: 780px;
  min-height: 121px;
  position: fixed;
  bottom: 0;
  height: 189px;
  top: auto !important;
  z-index: 600;
}
.hide-artifact {
  background: #f2f5f6;
  position: absolute;
  top: 0;
  height: 100%;
  width: 102%;
  margin-left: -1%;
}
.conversation__reply-box {
  width: 780px;
  border: none;
  position: absolute;
  top: 0;
  z-index: 100;
  background: #dff6f9;
  td {
    padding-top: 13px;
    vertical-align: top;
  }
}
.reply-tbox__cell {
  &:first-child {
    width: 40px;
  }
  &:nth-child(2) {
    width: 70px;
  }
  &:nth-child(3) {

  }
  &:nth-child(4) {
    width: 100px;
  }
  &:nth-child(5) {
    width: 40px;
  }
}
.reply-tbox__author {
  float: left;
  width: 60px;
  height: 60px;
  overflow: hidden;
  position: relative;
}
.reply-tbox__area {
  min-height: 60px;
}
.reply-tbox__file ,
.requests-step__action {
  float: right;
}


/* Страница Сообщения - окно Написать сообщение */
.new-msg__top-line {
  position: relative;
  color: #fff;
}
.new-msg__title {
  font-size: 17px;
  display: inline-block;
}
.new-msg__search {
  float: right;
  display: inline-block;
  width: 240px;
  background: #232323;
  border: none;
  padding: 0 10px 0 30px;
  margin-top: -5px;
  height: 28px;
  position: relative;
  font-size: 12px;
  color: #fff;
}
.new-msg__submit {
  background: url("../img/widget__search-icon.png") left 50% no-repeat;
  width: 30px;
  height: 28px;
  position: absolute;
  z-index: 100;
  border: 0;
  top: -5px;
}
.new-msg__close-btn {
  font-size: 18px;
  color: #fff;
  position: absolute;
  right: -32px;
}
.new-msg__block ,
.requests__choose-block {
  width: 580px;
  margin-top: 25px;
}
.new-msg__content {
  height: 60px;
  margin-left: 70px;
  padding: 0;
  display: table;
  .new-msg__name {
    color: #333;
    font-weight: bold;
    display: table-cell;
    vertical-align: middle;
  }
}

/* Страница Мои партнеры */
.my-partners__row {
  position: relative;
  padding: 10px;
  margin-top: -1px;
  border-top: 1px solid #e5e5e5;
  border-bottom: 1px solid #e5e5e5;
  width: 100%;
  display: table;
  @include bsizing;
  &:first-child {
    border-top: none;
  }
  &:last-child {
    border-bottom: none;
  }
  &.write-msg {
    .my-partners__rcell {
      width: 146px;
      padding: 0;
      .fa-envelope {
        margin-right: 7px;
      }
    }
  }
}
.my-partners__lcell ,
.my-partners__rcell {
  display: table-cell;
}
.my-partners__rcell {
  width: 155px;
  padding: 0 10px;
  position: relative;
  text-align: left;
  a {
    line-height: 1.7;
  }
}
.my-partners__del {
  position: absolute !important;
  bottom: 0;
}
.my-partners__cancel {
  position: absolute;
  right: 0;
}
.my-partners__image ,
.my-partners__image-sm ,
.company__image ,
.req-author__image ,
.req-executant__image {
  float: left;
  overflow: hidden;
  position: relative;
  img {
    position: inherit;
    z-index: 50;
    top: 0;
    left: 0;
    min-width: 100%;
    min-height: 100%;
  }
}
.my-partners__image ,
.company__image ,
.req-author__image ,
.req-executant__image {
  width: 80px;
  height: 80px;
}
.my-partners__image-sm {
  width: 60px;
  height: 60px;
  z-index: 500;
}
.req-author__image,
.company__image ,
.my-partners__image {
  background: #b1eaf1;
  &--image_exists {
    background: none !important;
  }
  &:before {
    font-family: 'Font Awesome 5 Free';
    font-style: normal;
    font-weight: 900;
    text-decoration: inherit;
    font-size: 32px;
    top: 50%;
    margin-top: -18px;
    color: #02abc0;
    position: absolute;
    z-index: 10;
  }
}
.company__image {
  &:before {
    content: "\f015";
    right: 32%;
  }
}
.req-author__image,
.my-partners__image {
  &:before {
    content: "\f007";
    right: 33%;
  }
}
.my-partners__content ,
.my-partners__content-sm ,
.req-author__content ,
.req-executant__content {
  overflow: hidden;
  padding: 0;
  line-height: 1.7;
}
.my-partners__content ,
.req-author__content ,
.req-executant__content  {
  margin-left: 90px;
}
.req-author__content ,
.req-executant__content  {
  max-height: 80px;
}
.req-executant__content  {
  div {
    &:not(.rate__lvl) {
      margin-top: -1px;
    }
  }
}
.req-author__content {
  div {
    &:last-child {
      margin-top: -1px;
    }
  }
}
.my-partners__content-sm {
  margin-left: 70px;
}
.my-partners__company-staff {
  color: #808080;
  span {
    color: #333;
  }
}
.my-partners__company-name {
  display: inline-block;
}
.my-partners__name ,
.my-partners__image ,
.choose-partner ,
.choosen-partner {
  position: relative;
  z-index: 500;
    box-sizing: content-box !important;
}
.my-partners__rcell {
  a {
    &:not(.my-partners__cancel) {
      position: relative;
      z-index: 500;
    }
  }
}
.my-partners__status {
  color: #333;
  font-style: italic;
}
.my-partners__inbox-msg ,
.my-partners__outbox-msg {
  color: #333;
  margin-top: 20px;
}
.my-partners__outbox-msg--before ,
.my-partners__inbox-msg--before {
  color: #808080;
}
.my-partners__rating-level {
  position: relative;
  width: 70px;
  margin-top: 3px;
}
.my-partners__more {
  text-align: center;
  span {
    display: inline-block;
    margin: 40px auto 0 auto;
  }
}
/* Рекомендации в партнеры */
.rec__head {
  background: #feebb3;
  color: #333;
  padding: 13px 10px;
  border-bottom: 1px solid #e4d3a1;
}

/* Поиск партнеров */
.my-partners__search-rslt {
  padding: 12px 11px;
  border-bottom: 1px solid #d9dcdd;
  text-align: left;
}
.search-match {
  background: #dff6f9;
  padding: 3px;
}

.my-partners__wrapper {
  /* height: 300px; */
  position: relative;
}
.wrapper-top ,
.wrapper-btm  {
  content: '';
  position: absolute;
  left: 0;
  height: 19px;
  width: 100%;
  z-index: 100;
}
.wrapper-top {
  background: url('../img/my-partners__wrapper-top.png') repeat-x;
  top: 0;
}
.wrapper-btm {
  background: url('../img/my-partners__wrapper-btm.png') repeat-x;
  bottom: 0;
}
.my-partners__slide-list-down ,
.my-partners__slide-list-up {
  position: absolute;
  width: 90px;
  left: 0;
  height: 100%;
  display: table;
  text-align: center;
  border-right: 1px solid #e5e5e5;
  line-height: 1.7;
  div {
    display: table-cell;
    vertical-align: middle;
    i {
      display: block;
    }
  }
}
.slide-hidden {
  display: none;
}
.my-partners__slide-content {
  margin-left: 90px;
  overflow-y: scroll;
  /* height: 300px; */
}


/* Страница Заявки */
.section-user-request__block {
  position: relative;
  margin-bottom: 60px;

}

.requests__wrapper {
  height: 344px;
  overflow: hidden;
  &--sm {
    height: auto;
  }
}
.requests__open {
  position: absolute;
  top: calc(100% - 1px);
  left: 0;
  z-index: 1000;
  display: block;
  width: 100%;
  background: #c5eff5;
  padding: 12px 0;
  text-align: center;
  border-bottom: 1px solid #91e2ec;
  border-top: 1px solid #91e2ec;
  box-shadow: 0 0px 1px 0px rgba(51, 51, 51, 0.34);
}
.requests__last {
  font-style: italic;
}
.requests-step__line {
  position: relative;
  padding-top: 10px;
}
.requests-step__title {
  display: inline-block;
  .text {
    margin-left: 10px;
  }
  &__compare {
    display: block;
  }
}
.requests-step__indicator {
  float: right;
  margin-top: 8px;
  span {
    width: 20px;
    height: 5px;
    float: left;
    margin-left: 5px;
    background: #ccc;
  }
}
.requests-step__first {
  .requests-step__indicator {
    span {
      &:first-child {
        background: #03b7d3;
      }
    }
  }
}
.requests-step__second {
  .requests-step__indicator {
    span {
      &:first-child {
        background: #03b7d3;
      }
      &:nth-child(2) {
        background: #03b7d3;
      }
    }
  }
}
.requests-step__third {
  .requests-step__indicator {
    span {
      &:first-child {
        background: #03b7d3;
      }
      &:nth-child(2) {
        background: #03b7d3;
      }
      &:nth-child(3) {
        background: #03b7d3;
      }
    }
  }
}
.requests-step__form {
  padding: 28px 20px 20px 20px;
  &--title {
    margin: 20px 0 17px 0;
    display: block;
    &:first-child {
      margin: 0 0 17px 0;
    }
    i , a {
      font-style: normal;
      margin-left: 29px;

    }
  }
}

.requests-step__form-2 {
  padding: 20px 10px 10px 10px;
  border-bottom: 1px solid #e5e5e5;
}
.requests-step__center {
  text-align: center;
  margin: 13px 0px -5px 0px;
  .add-requests__label {
    margin-bottom: 23px;
  }
}
.req__col {
  &:first-child {
    margin-top: 10px;
    width: 35px;
    float: left;
  }
  &:nth-child(3) {
    margin: 0 35px;
    input {
      width: 100%;
      height: 30px;
      border: 1px solid #e5e5e5;
      color: #333;
      padding: 0 8px;
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      background: #fff;
      margin-bottom: 18px;
      @include bsizing;
      @include bradius(4px);
    }
  }
  &:nth-child(2) {
    margin-top: 8px;
    width: 35px;
    float: right;
    text-align: right;
    .far, .fas {
      font-size: 15px;
    }
  }
}
.requests__add-row {
  padding: 12px 10px 12px 45px;
}
.request-lvl__block {
  width: 160px;
  padding: 10px;
  text-align: center;
  .is-grey-text {
    text-align: left;
    display: block;
    line-height: 1.4;
  }
  .is-or-text {
    display: block;
  }
}
.lvl-descr__first {
  color: #ff6c08;
}
.lvl-descr__second {
  color: #fec315;
}
.lvl-descr__third {
  color: #00bea5;
}
.request-lvl__slider {
  margin:20px auto 0 auto;
}
.requests__edit-btn ,
.req-status__open-btn ,
.confirm__block-btn {
  text-align: center;
  height: 30px;
  line-height: 15px;
  display: table-cell;
  color: #fff;
  border: none;
  @include bsizing;
  padding: 8px 13px;
}
.requests__rec ,
.requests-partners__slide {
  .my-partners__rcell {
    width: 80px;
    padding: 0 0 0 10px;
      box-sizing: content-box !important;
  }
}
.requests__choose {
  padding: 12px 10px;
  border-top: 1px solid #e5e5e5;
}
.requests-eq__item {
  padding: 10px 10px 0 10px;
  position: relative;
  width: 100%;
  min-height: 63px;
  display: block;
  border-bottom: 1px solid #e5e5e5;
  @include bsizing;
  &:last-child {
    border-bottom: none;
  }
}
.requests-eq__no {
  width: 7%;
  display: inline-block;
  line-height: 1.6;
}
.requests-eq__pos-descr {
  display: inline-block;
  width: 48%;
  .position-name {
    display: inline-block;
    vertical-align: top;
    &:first-child {
      width: 55%;
    }
    &:nth-child(2) {
      width: 40%;
    }
    span {
      p {
        white-space: nowrap ;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
    p {
      margin-bottom: 9px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}
.is-highlight-r ,
.is-highlight-l {
  background: #fef7e1;
  height: 22px;
  line-height: 22px;
  margin-top: -5px;
}
.is-highlight-r {
  display: inline-block;
  padding-right: 10px;
  @include bradius(0 11px 11px 0);
}
.is-highlight-l {
  padding-left: 10px;
  margin-left: -10px;
  @include bradius(11px 0 0 11px);
}
.position-label {
  width: 29%;
  display: inline-block;
}
.requests-eq__img {
  position: absolute;
  right: 10px;
  top: 10px;
  margin: 0;
  padding: 0;
  img {
    margin: 0;
    padding: 0;
    height: 40px;
    display: block;
  }
  &:before {
    content: '';
    position: absolute;
    width: 46px;
    height: 47px;
    z-index: 10;
    left: -1px;
    top: -5px;
  }
}
.requests-eq__img--more {
  &:before {
    background: url('../img/requests-eq__brd.png') no-repeat;
    background-position: 0 -93px;
  }
}
.requests-eq__img--2 {
  &:before {
    background: url('../img/requests-eq__brd.png') no-repeat;
    background-position: 0 -46px;
  }
}
.requests-eq__img--1 {
  &:before {
    background: url('../img/requests-eq__brd.png') no-repeat;
    background-position: 0 1px;
  }
}
.requests-eq__inner {
  overflow: hidden;
  width: 40px;
  height: 40px;
}
.requests-eq__pos-row {
  padding-bottom: 10px;
  min-height: 40px;
}
.requests-info__block {
  padding: 10px;
  background: #fff;
  min-height: 180px;
  @include bsizing;
  @include tranz;
  &:hover {
    cursor: default;
    background: #fff;
  }
}
.new-msg__modal {
  .requests-info__block {
    &:hover {
      cursor: pointer;
      background: #fafbfb;
    }
  }
}
.req-active {
  position: relative;
  background: #fafbfb;
  &:after {
    z-index: 9000;
    position: absolute;
    content: "\f00c";
    font-family: 'Font Awesome 5 Free';
    font-style: normal;
    font-weight: 900;
    text-decoration: inherit;
    width: 15px;
    height: 15px;
    font-size: 15px;
    top: 6px;
    color: #ff6e09;
    right: 10px;
  }
}
.req-choose__btn {
  margin-top: 10px;
  @extend .is-rounded;
}
.requests-info__photo {
  background: #f2f2f2;
  width: 160px;
  height: 160px;
  border: 1px solid #d6d6d6;
  float: left;
  position: relative;
  overflow: hidden;
  @include bsizing;
  img {
    position: relative;
    z-index: 50;
  }
  &:before {
    content: "\f03e";
    font-family: 'Font Awesome 5 Free';
    font-style: normal;
    font-weight: 900;
    text-decoration: inherit;
    width: 36px;
    font-size: 32px;
    top: 50%;
    margin-top: -17px;
    right: 38%;
    color: #ccc;
    position: absolute;
    z-index: 10;
  }
}
.requests-info__content {
  margin-left: 170px;
  p {
    line-height: 1.7;
  }
}
.requests-ind {
  float: left;
}
.requests-descr {
  color: #333;
  margin-left: 130px;
  overflow: hidden;
  display: block;
}
.requests-partners__slide {
  margin-left: 0;
  overflow-y: scroll;
  position: relative;
  height: 503px;
}
.requests-partners__wrapper {
  height: 503px;
  position: relative;
}
.requests__search {
  width: 100%;
  background: #fff;
  color: rgba(51, 51, 51, 0.5);
  border: 1px solid #fff;
  padding: 0 10px 0 30px;
  height: 40px;
  position: relative;
  font-size: 12px;
  color: #333;
  -webkit-box-sizing: border-box !important;
  -moz-box-sizing: border-box !important;
  box-sizing: border-box !important;
  &::-webkit-input-placeholder {
    color: rgba(51, 51, 51, 0.5);
  }
  &:-moz-placeholder {
    color: rgba(51, 51, 51, 0.5);
  }
  &::-moz-placeholder {
    color: rgba(51, 51, 51, 0.5);
  }
  &:-ms-input-placeholder  {
    color: rgba(51, 51, 51, 0.5);
  }
}
.requests-modal__submit {
  position: absolute;
  left: 0;
  top: 7px;
  border: none;
  width: 25px;
  height: 25px;
  z-index: 5;
  background: url("../img/my-companion__search-icon.png") left 50% no-repeat;
}
.requests__footer {
  padding: 10px;
  line-height: 30px;
}
.requests__add-partner {
  color: #fff;
  height: 30px;
  width: 95px;
  float: right;
  text-align: center;
}
.requests__submit ,
.requests__next-step ,
.my-company-profile__submit ,
.my-company-search__submit {
  border: 1px solid #fff !important;
}
.requests-step__status {
  &--formed {
    .req-status__title {
      background: #26c5d9;
    }
    .req-status__content {
      color: #999;
    }
  }
  &--active {
    .req-status__title {
      background: #feb64f;
    }
  }
  &--canceled {
    .req-status__title {
      background: #bdccd4;
    }
  }
  &--done {
    .req-status__title {
      background: #00bea5;
    }
  }

}
.req-status__title {
  padding: 13px 10px;
  color: #fff;
}
.req-status__content {
  line-height: 1.5;
  padding: 12px 10px 10px 10px;
  p ,
  div  {
    margin-bottom: 7px;
  }
}
.req-addressee__list ,
.requests-step__author ,
.requests-step__executant ,
.requests-comment__block {
  padding: 10px;
}
.req-addressee__item {
  float: left;
  width: 50%;
  margin-top: 10px;
  &:first-child {
    margin-top: 0;
  }
  &:nth-child(2) {
    margin-top: 0;
  }
  &:nth-child(even) {
    width: 45%;
  }
  &:nth-child(odd) {
    width: 55%;
  }
  &--rejected {
    .req-addressee__answer {
      color: #e55005;
    }
  }
  &--processing ,
  &--inactive {
    .req-addressee__answer {
      color: #808080;
    }
  }
  &--answered {
    .req-addressee__answer {
      color: #333;
    }
  }
}
.req-addressee__link {
  display: inline-block;
  .req-addressee__name ,
  .req-addressee__comment {
    display: inline-block;
    border-bottom: 1px solid transparent;
    margin-bottom: 3px;
  }
  &:hover {
    .req-addressee__name {
      border-bottom: 1px solid #02abc0;
    }
  }
}
.req-addressee__answer {
  display: block;
}
.req-addressee__photo {
  width: 40px;
  height: 40px;
  float: left;
  overflow: hidden;
  position: relative;
  img {
    height: 100%;
  }
}
.req-addressee__descr {
  margin-left: 50px;
  padding-top: 4px;
  overflow: hidden;
}
.req-addressee__msg {
  margin: 5px 0 0 10px;
  display: inline-block;
}
.req-addressee__comment {
  @extend .is-long-row;
  font-style: italic;
}
.requests-eq__form {
  padding-top: 20px;
  margin-left: 7%;
  border-top: 1px solid #e5e5e5;
  &--time {
    margin-left: 7%;
    .requests-eq__label {
      &:first-child {
        padding: 0;
      }
    }
    .requests-eq__date {
      margin-left: -4px;
    }
  }
}
.requests-eq__radio {
  &--cover {
    display: inline-block;
    input + label {
      width: 100px;
      display: inline-block;
      vertical-align: top;
    }
    input {
      &:checked + label {
        font-weight: bold;
      }
    }
  }
  &--line {
    margin-bottom: 10px;
  }
}
.requests-eq__label {
  display: block;
  position: relative;
  padding: 15px 0;
  .is-grey-text {
    margin: 9px 0 0 0;
  }
  input , select , textarea , .requests-eq__date {
    padding: 0 8px;
    float: right;
    height: 30px;
    border: 1px solid #e5e5e5;
    color: #333;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background: #fff;
    @include bsizing;
    @include bradius(4px);
  }
  select {
    float: right;
    margin: -9px 5px 0 0;
    width: 80px;
    padding: 0 5px;
    background: #fff url("../img/select__triangle-blue.png") 64px 50% no-repeat;
  }
  textarea {
    height: 60px;
    resize: vertical;
    padding: 8px;
  }
  .i-date {
    color: #26c5d9;
    font-size: 20px;
    margin-left: 7px;
    vertical-align: sub;
    display: inline-block;
    cursor: pointer;
  }
}
.requests-eq__input--full {
  width: 71%;
}
.requests-eq__date {
  width: 100px;
  float: none !important;
}
.js__one_item_price__position {
  margin-right: 46%;
  width: 25%;
}
.request_position__total_price {
  position: absolute;
  bottom: -8px;
  left: 60%;
}


.compare-link {
  text-align: right;
}
.feedback__check-line {
  margin-left: 5px;
}
/* Слайдер заявок */
.feedback__head {
  height: 81px;
  overflow: hidden;
  border-bottom: 1px solid rgba(51, 51, 51, 0.1);
  &--cloned {
    display: none;
  }
}

.feedback__img {
  width: 80px;
  height: 81px;
  float: left;
  overflow: hidden;
  img {
    height: 100%;
  }
}
.feedback__info {
  overflow: hidden;
  margin-left: 90px;
  text-align: left;
  padding: 10px 0;
  line-height: 1.7;
  .far, .fas {
    display: none;
  }
  &--comment {
    .fas, .far {
      display: block;
      font-size: 17px;
    }
  }
}
.feedback__item {
  &--rejected {
    background: #666 !important;
    .feedback__fix {
      background: #666 !important;
    }
    .feedback__name ,
    .feedback__content {
      color: #fff;
      min-height: 120px;
      display: block;
      position: relative;
      .feedback__block {
        width: 180px;
        top: 45px;
        position: absolute;
        .far, .fas {
          display: block;
          font-size: 40px;
          margin: -30px 0 15px 0px;
        }
      }
    }
  }
  &--processing {
    .feedback__content {
      color: #999;
      min-height: 120px;
      display: block;
      position: relative;
      .feedback__block {
        width: 180px;
        top: 45px;
        position: absolute;
        .far, .fas {
          display: block;
          font-size: 40px;
          margin: -30px 0 15px 0px;
        }
      }
    }
  }
  &:hover {
    cursor: pointer !important;
    box-shadow: 0px 0px 3px 1px rgba(51, 51, 51, 0.5) !important;
  }
  &--unactual {
    .feedback__content {
      .feedback__row {
        text-decoration: line-through;
      }
      .feedback__total-row {
        div {
          text-decoration: line-through;
        }
      }
    }

  }
}
.feedback__row ,
.feedback__total-row {
  line-height: 1.7;
  height: 60px;
  padding: 10px 0;
  border-bottom: 1px solid rgba(51, 51, 51, 0.1);
  @include bsizing;
}
.feedback__comment-row {
  padding: 10px;
  font-style: italic;
  color: #999;
  background: #fff;
  line-height: 1.7;
  text-align: center;
  position: relative;
  &.has-comment {
    text-align: left;
  }
}
.show-helper {
  position: relative;
  cursor: pointer;
}
.feedback__choose-executant,
.feedback__choose-executant__unactual,
.feedback__choose-executant__unactual-disabled
{
  position: relative;
  background: #fee480;
  color: #333;
  height: 44px;
  width: 180px;
  line-height: 44px;
  @include bsizing;
  .feedback__helper {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 180px;
  }
}

.feedback__choose-executant__unactual {
  background: #25a7b5;
  color: #fff;

  &-disabled {
    background: #ddd;
    color: #fff;
    cursor: default;
  }
}

.feedback__fix {
  position: fixed;
  top: 0;
  width: 180px;
  border-radius: 0 !important;
  background: #fff;
  z-index: 100;
}
.feedback__nums-block {
  position: absolute;
  width: 180px;
  top: 60px;
  padding-top: 65px;
  border-right: 1px solid rgba(51, 51, 51, 0.1);
}
.feedback__main-title {
  color: #fff;
  padding: 10px;
}
.feedback__nums ,
.feedback__nums-total ,
.feedback__nums-comment {
  line-height: 1.7;
  padding: 10px 0;
  height: 40px;
  span {
    display: inline-block;
    vertical-align: top;
    &:first-child {
      margin-left: 10px;
      width: 35px;
    }
    &:nth-child(2) {
      float: right;
      width: 135px;
    }
    p {
      @extend .is-long-row;
    }
  }
}
.rubl {
  font-family: 'als_rublregular' !important;
  font-weight: normal !important;
  font-style: normal !important;
  font-size: 14px;
}






/* Объявления */
.advpost__add-new ,
.request__add-new{
  margin-top: 60px;
}
.advpost__select-sidebar ,
.request__select-sidebar {
  background: #fff url("../img/select__triangle-blue.png") right 50% no-repeat;
  width: 100%;
  height: 30px;
  border: 1px solid #bfbfbf;
  padding: 0 5px;
  margin: 3px 0 0;
  color: #333;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  @include bsizing;
  @include bradius(4px);
  &--wrap {
    margin: 35px 0 28px 0 !important;
  }
}
.advpost__check-block ,
.request__check-block ,
.submit--cover ,
.submit--rcover ,
.submit--ncover {
  position: relative;
}
.advpost__btn-block ,
.request__btn-block {
  width: 180px;
  padding-bottom: 8px;
  margin: 0 0 0 -10px;
  border-bottom: 1px solid #e5e5e5;
}
.advpost__reset-btn ,
.request__reset-btn ,
.send-info__btn {
  text-align: center;
  width: 100%;
  height: 30px;
  display: block;
  color: #fff;
  background: none;
  border: none;
  @include bsizing;
  &--wrap {
    width: 160px;
    margin-left: 10px;
  }
}
.advpost__summ-sidebar {
  width: 75px;
  height: 30px;
  border: 1px solid #e5e5e5;
  padding: 0 8px;
  margin: 3px 0 0;
  color: #333;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: #fff;
  @include bsizing;
  @include bradius(4px);
  &--r {
    float: right;
  }
  &--wrap {
    margin: 36px 0;
  }
  &--lowwrap {
    margin: 36px 0 0 0;
  }
}
.advpost__form {
  padding: 9px 0 0;
  .advpost__line-label {
    position: relative;
    padding: 11px 0;
    display: block;
    .advpost__input , select , textarea {
      margin-top: -2px;
      float: right;
      height: 30px;
      border: 1px solid #e5e5e5;
      color: #333;
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      background: #fff;
      @include bsizing;
      @include bradius(4px);
    }
    .advpost__input {
      width: 160px;
      padding: 0 8px;
      margin-right: 190px;
    }
    .range-block {
      position: absolute;
      right: 0;
      margin: -2px 0 0 0;
      i {
        margin-top: 8px;
        display: inline-block;
        position: absolute;
        left: -20px;
      }
      .range-input {

        margin: 0;
      }
    }
    .advpost__new-input {
      position: absolute;
      right: 0;
      bottom: 17px;
    }
    select , textarea {
      width: 350px;
    }
    select {
      padding: 0 8px;
      background: #fff url("../img/select__triangle-blue.png") right 50% no-repeat;
    }
    .advpost__ta-keywords {
      height: 60px;
      resize: none;
      padding: 8px;
    }
    .advpost__ta-title {
      padding: 8px 30px 8px 8px;
      height: 60px;
      resize: none;
    }
    .advpost__ta-posttext {
      height: 90px;
      padding: 8px 30px 8px 8px;
      resize: vertical;
    }
    span {
      display: inline-block;
      margin: 7px 0 0 7px;
    }
  }
}
.advpost__radio {
  &--cover {
    float: right;
    width: 350px;

    label {
      padding: 0;
      margin-right: 13px;
        box-sizing: content-box !important;
    }
    input + label {
      width: 50px;
      display: inline-block;
    }
    input {
      &:checked + label {
        font-weight: bold;
      }
    }
  }
  &--line {
    margin-bottom: 15px;
  }
}
.add-advpost__file--wrap {
  text-align: left;
  margin-left: 35%;
}
.advpost__tech-choosen ,
.employee__name-choosen ,
.employee__me-choosen {
  margin-bottom: 10px;
}
.tech-choose ,
.partner-choose {
  display: inline-block;

}


/* Парк техники */
.eq__add-new ,
.eq__wrap ,
.advpost__add-new ,
.advpost__wrap ,
.request__add-new ,
.request__wrap {
  background: #fff;
  padding: 10px;
  position: relative;
}
.eq__form-sidebar ,
.advpost__form-sidebar ,
.request__form-sidebar {
  position: relative;
  label {
    display: block;
    margin-bottom: 10px;
    &:last-child {
      margin-bottom: 0;
    }
  }
}
.eq__add-btn ,
.advpost__add-btn ,
.request__add-btn {
  display: block;
  padding: 0;
}
.eq__form {
  position: relative;
  .eq__label {
    display: inline-block;
    margin-bottom: 10px;
    &:last-child {
      margin-bottom: 0;
    }
  }
}
.eq__form-title ,
.advpost__form-title ,
.request__form-title {
  margin-bottom: 10px;
}
.eq__check-all ,
.eq__check-none ,
.advpost__check-all ,
.advpost__check-none ,
.request__check-all ,
.request__check-none {
  position: absolute;
  top: 0;
  right: 0;
}
.eq__block ,
.advpost__block {
  width: 100%;
}
.eq__item ,
.advpost__item {
  background: #fff;
  position: relative;
  padding: 0;
  width: 48%;
  margin: 0 1% 20px 1%;
  float: left;
  @include bsizing;
}
.eq-desrc__slider--wrap {
  overflow: hidden;
  height: 175px;
  padding: 10px 0;
  border-bottom: 1px solid #e5e5e5;
  .frame {
    height: 175px;
    line-height: 1;
  }
}
#eq-desrc__slider {
  ul {
    li {
      width: 384px;
      background: none;
      img {
        width: 100%;
        display: inline-block;
      }
    }
  }
}
.eq-desrc__image {
  position: relative;
  overflow: hidden;
  padding: 10px;
  border-bottom: 1px solid #e5e5e5;
}
.eq-photo {
  padding: 0;
  margin: 0 auto;
  display: block;
}
.eq-desrc__space {
  position: relative;
  width: 100%;
  height: 100%;
  display: table;
  background: #fef7e1;
  border: 1px solid #feebb3;
  @include bsizing;
}
.eq-desrc__helpers {
  display: table;
  cursor: pointer;
  color: #fe8e0d;
  position: absolute;
  width: 90%;
  height: 90%;
  top: 5%;
  left: 5%;
  z-index: 100;
  text-align: center;
  .fa-plus {
    font-size: 12px;
    margin-right: 3px;
  }
  .fa-picture-o {
    font-size: 20px;
    display: inline;
    vertical-align: sub;
    margin-right: 6px;
  }
}
.eq-desrc__text {
  padding: 10px;
  position: relative;
  p {
    line-height: 1.7;
    .far, .fas {
      font-size: 14px;
      margin-right: 7px;
    }
  }
}
.eq-desrc__create {
  padding: 10px;
  border-top: 1px solid #e5e5e5;
  .fa-plus {
    font-size: 12px;
    margin-right: 3px;
  }
  .fa-list-alt {
    font-size: 20px;
    margin-right: 5px;
    vertical-align: sub;
  }
}
/* Добавить технику модальное окно*/
.modal__filled {
  padding: 0 0 12px 0;
  margin-top: -7px;
  width: 100%;
  border-bottom: 1px solid #e5e5e5;
}
#eq__progressbar {
  float: right;
  width: 340px;
  margin-top: 11px;
  position: relative;
  margin-right: 6px;
}
.ui-widget-content {
  background: #dee5e9;
  height: 2px;
}
.ui-widget-header {
  background: #ff6c08;
  height: 2px;
  @include tranz;
}
.eq__progressval {
  top: -13px;
  left: -4px;
  position: absolute;
  width: 20px;
  height: 14px;
  background: url('../img/eq__progressbar-val.png') no-repeat;
  background-position: 0 0;
  color: #bdccd4;
  font-size: 10px;
  padding-left: 13px;
  @include tranz;
  i {
    font-style: normal;
    &.hundred {
      left: -30px;
      position: absolute;
    }
  }
}
.eq__form {
  padding: 9px 0 10px 0;
  .eq__line-label {
    position: relative;
    padding: 11px 0;
    display: block;
    span {
      display: inline-block;
      margin-top: 7px;
    }
    input , select {
      margin-top: -2px;
      float: right;
      width: 350px;
      height: 30px;
      border: 1px solid #e5e5e5;
      color: #333;
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      background: #fff;
      @include bsizing;
      @include bradius(4px);
    }
    input {
      padding: 0 8px;
    }
    select {
      padding: 0 8px;
    }
    #eq__brand , #eq__type {
      background: #fff url("../img/select__triangle-blue.png") right 50% no-repeat;
    }
    #eq__unit {
      width: 327px;
      margin-right: 24px;
    }
  }
}

.add-equipment__file--wrap {
  text-align: center;
  padding-top: 9px;
}
.tooltip {
  display: inline-block;
  position: absolute;
  padding: 0 5px;
  font-size: 18px;
  right: 0;
  color: #02abc0;
  bottom: 14px;
  cursor: pointer;
  .tooltip__msg {
    opacity: 0;
    visibility: hidden;
    width: 150px;
    display: block;
    font-size: 12px;
    color: #333;
    background: #fef7e1;
    padding: 10px;
    position: absolute;
    bottom: 20px;
    right: 20px;
    z-index: 10;
  }
  &:hover {
    .tooltip__msg {
      opacity: 1;
      visibility: visible;
    }
  }
}

/* Профиль - Анкета */
sup {
  color: #feb212;
  margin-left: 3px;
}
.hr-grey {
  width: calc(100% + 40px);
  height: 1px;
  margin: 13px 0 32px -20px;
  background: #e5e5e5;
}
.my-pers-profile__block ,
.security__block {
  background: #fff;
  padding: 20px;
}
.my-pers-profile__photo ,
.my-company-profile__photo ,
.security__photo {
  width: 180px;
  height: 180px;
  position: relative;
}

.my-pers-profile__photo {
  background: #b1eaf1;
}
.my-pers-profile__form ,
.security__form ,
.my-pers-profile__form {
  &--title-sm {
    margin: 20px 0 17px 0;
    display: block;
    &:first-child {
      margin: 0 0 17px 0;
    }
  }
  &--title {
    margin: 0 0 25px 0;
    display: block;
  }
}
.my-pers-profile__line-label ,
.my-company-profile__line-label ,
.security__line-label ,
.req__line-label {
  position: relative;
  padding: 14px 0;
  display: block;
  input , select , textarea {
    margin-top: -2px;
    float: right;
    height: 30px;
    border: 1px solid #e5e5e5;
    color: #333;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background: #fff;
    @include bsizing;
    @include bradius(4px);
  }
  .my-pers-profile__input , .my-pers-profile__text , .my-company-profile__input , .security__input , select , textarea , .req__input {
    width: 65%;
    float: right;
  }
  .my-pers-profile__text {
    min-height: 80px;
  }
  .my-pers-profile__show {
    margin-top: 8px;
    position: absolute;
    right: 3px;
  }
  .security__input {
    margin-top: 4px;
    .security__input-sm {
      margin-top: -5px;
    }
  }
  .my-pers-profile__input-sm ,
  .security__input-sm {
    width: calc(95% - 160px);
    float: left;
  }
  select {
    padding: 0 8px;
    background: #fff url("../img/select__triangle-blue.png") right 50% no-repeat;
  }
  input {
    padding: 0 8px;
  }
  textarea {
    padding: 8px;
    resize: vertical;
  }
  .my-company-profile__ta {
    &--high {
      height: 70px;
    }
    &--mid {
      height: 52px;
    }
  }
  span {
    display: inline-block;
    margin-top: 4px;
    line-height: 1.5;
  }
}
.profile-show-in-contact {
  .my-pers-profile__show {
    top: 12px;
  }
}
.is-mtop-2 {
  margin-top: 2px !important;
}
.is-mtop-5 {
  margin-top: -5px !important;
}
.my-company-profile__line-label {
  span {
    line-height: 1.7;
  }
}
.prop {
  padding: 6px 0 20px 0 !important;
}
.my-company-profile__chk {
  margin-top: 4px;
  .my-pers-profile__input {
    line-height: 1.7;
    margin-top: 5px;
  }
}
.my-pers-profile__submit {
  padding: 8px 10px 8px 35px;
  text-align: center;
  color: #fff;
  background: url('../img/submit-icon-white.png') left center no-repeat;
  border: none;
  @include bsizing;
  &--wrap {
    margin: 14px 0 0 35%;
  }
}
.my-pers-profile__space {
  position: relative;
  width: 100%;
  height: 100%;
  display: table;
}
.my-pers-profile__helpers {
  display: table;
  position: absolute;
  width: 100%;
  height: 100%;
  top:0;
  z-index: 100;
  text-align: center;
  .helpers-signs__icons {
    .fa-user ,
    .fa-home {
      font-size: 65px;
      margin-top: 20px;
      margin-bottom: 5px;
    }
  }
}

/* Профиль - Моя компания */
.my-company-profile__photo,
.security__photo {
  background: #fedf83;
  padding: 15px;
  overflow: hidden;
  @include bsizing;
}
.my-company-profile__line-label {
  padding: 3px 0 35px 0;
}
.my-company-profile__line-label-r {
  margin: 0 0 30px 0;
}
.my-company-profile__helpers ,
.security__helpers {
  display: table;
  position: absolute;
  z-index: 100;
  text-align: center;
  width: 150px;
  height: 150px;
  top: 15px;
  left: 15px;
  .helpers-signs__content {
    color: #fe9f0f;
    text-align: left;
    .far, .fas {
      font-size: 65px;
      display: block;
    }
    .fa-street-view {
      margin: 5px 0 10px 0;
    }
    .fa-envelope {
      margin: -10px 0px 10px -2px;
    }
  }
}
.my-company-radio--cover {
  width: 65%;
  float: right;
  position: relative;
  label , input[type='radio'] {
    display: inline-block;
    vertical-align: top;
  }
  label {
    margin-right: 2%;
  }
  input {
    &:checked + label {
      font-weight: bold;
    }
  }
  input + label {
    /* width: 27%; */
    display: inline-block;
  }
  .tooltip {
    position: relative;
    bottom: 4px;
    display: inline-block;
    font-size: 18px;
    color: #02abc0;
  }
}
.my-company-radio--line {
  margin-bottom: 35px;
}
.my-company-profile__block {
  background: #fff;
  padding: 20px 20px 47px 20px;
  position: relative;
}
.block__footer {
  position: absolute;
  width: 100%;
  bottom: 0;
  left: 0;
}
.check-group {
  width: 50%;
  &:nth-child(even) {
    float: right;
    &:after {
      content: '.';
      display: block;
      height: 0;
      clear: both;
      visibility: hidden;
    }
  }
}
/* Профиль - Результат поиска */
.my-company-search__title {
  margin: 30px 0;
  &--high {
    margin: 23px 0;
    line-height: 1.5;
  }
  span {
    line-height: 1.3;
    display: block;
    margin-top: 30px;
  }
}
.my-company-search__res {
  background: #fff;
  padding: 0 0 40px 0;
  position: relative;
}
.my-company-search__treatment ,
.my-company-search__no-dir {
  background: #fff;
  padding: 0;
  position: relative;
}
.my-company-search__content {
  display: table;
  width: 100%;
}
.my-company-search__row {
  position: relative;
  padding: 10px;
  margin-top: -1px;
  width: 50%;
  display:  table-cell;
  @include bsizing;
  &:first-child {
    border-right: 1px solid #e5e5e5;
  }
}
.my-company-search__notes {
  margin: 35px 0 0 0;
  line-height: 1.6;
  a {
    margin-left: 19px;
  }
}
.my-company-search__send-btn {
  display: block;
  padding: 0;
  &--wrap {
    margin-top: 10px;
    width: 68%;
  }
}
/* Отправить сообщение со страницы Профиль - поиск компании */
.send-mail__form {
  textarea {
    width: 100%;
    min-height: 67px;
    padding: 8px 30px 8px 8px;
    margin: 0 0 25px 0;
    resize: vertical;
  }
}
.send-invite__label {
  position: relative;
  padding: 11px 0;
  display: block;
  margin-top: 20px;
  span {
    display: inline-block;
    margin-top: 7px;
  }
}
.send-mail__input , textarea {
  margin-top: -2px;
  float: right;
  height: 30px;
  border: 1px solid #e5e5e5;
  color: #333;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: #fff;
  @include bsizing;
  @include bradius(4px);
}
.send-mail__input {
  width: 350px;
  padding: 0 8px;
}
.send-mail__radio {
  &--cover {
    float: right;
    width: 350px;
    label {
      padding: 0;
      margin: 0;
    }
    input + label {
      width: 145px;
      display: inline-block;
    }
    input {
      &:checked + label {
        font-weight: bold;
      }
    }
  }
  &--line {
    margin-bottom: 15px;
  }
}

/* Профиль Анкета компании */
.my-com-profile__notes {
  margin: 40px auto 20px auto;
  display: block;
  text-align: center;
}
/* Профиль - Безопасность */
.phone-in-use {
  display: inline-block;
  margin: 0;
  float: none;
}
.change-data ,
.confirm-data {
  margin: 0 0 0 10px;
  span {
    line-height: 1;
  }
}
/* Профиль Тарифный план */
#new-service-popup {
  display: none;
}
.solution-pay__block ,
.solution-time__block {
  width: 180px;
  position: relative;
}
.solution-time__block {
  min-height: 140px;
}
.solution-time__title {
  padding-left: 60px;
  height: 115px;
  padding-top: 10px;
  display: table;
  span {
    display: table-cell;
    vertical-align: middle;
    b {
      font-size: 18px;
      font-weight: bold;
      display: block;
    }
  }
}
.solution-time__info ,
.solution-about__block ,
.solution-descr__title ,
.solution-descr__choose {
  padding: 15px 10px;
}
.solution-pay__balance {
  padding: 15px;
  border-bottom: 1px solid #eaeaea;
  b {
    font-size: 18px;
    font-weight: bold;
    margin-right: 3px;
    span {
      font-size: 18px;
      font-weight: bold;
    }
  }
}
.solution-pay__history {
  display: inline-block;
  padding: 15px;
}
.solution-pay__fill ,
.solution__new ,
.requests__submit ,
.requests__next-step ,
.my-company-profile__submit ,
.my-company-search__submit ,
.req-img__submit ,
.req-choose__btn {
  height: 40px;
  line-height: 40px;
  padding: 0 11px;
  text-align: center;
  display: block;
  font-size: 12px;
  color: #fff;
  background: #02abc0;
}
.solution-about__name ,
.solution-descr__name {
  margin-bottom: 5px;
  b {
    font-size: 18px;
    font-weight: bold;
    margin-right: 3px;
  }
}
.solution-descr__price {
  label {
    font-weight: bold;
  }
  .solution-best {
    background: #e5f8f6;
    color: #009587;
    padding: 4px;
    @include bradius(4px);
    margin-left: 10px;
  }
  .solution-info {
    background: #feebb3;
    padding: 4px;
    @include bradius(4px);
    margin-left: 3px;
  }
}
.solution-about__price {
  color: #00bea5;
  display: inline-block;
}
.solution__change {
  position: absolute;
  right: 10px;
  top: 20px;
  a {
    display: inline-block;
    width: 110px;
    height: 30px;
    padding: 0;
  }
}
.solution-about__more {
  display: inline-block;
  margin-left: 30px;
  position: relative;
  &:before {
    content: "\f111";
    font-family: FontAwesome;
    font-style: normal;
    font-weight: normal;
    text-decoration: inherit;
    width: 5px;
    height: 5px;
    font-size: 5px;
    bottom: 5px;
    color: #bdccd4;
    position: absolute;
    left: -19px;
  }
}
.solution-descr__title {
  border-bottom: 1px solid #eaeaea;
}
.solution-descr__content {
  display: table;
  border-bottom: 1px solid #eaeaea;
}
.solution-descr__people {
  display: table-cell;
  width: 180px;
  height: 190px;
  padding: 15px 10px;
  border-right: 1px solid #eaeaea;
}
.solution-descr__text {
  color: #666;
  display: table-cell;
  padding: 15px 10px;
}
.simple-list {
  margin: 15px 5px;
  li {
    margin: 17px 0;
    padding-left: 10px;
    position: relative;
    &:before {
      content: "\f111";
      font-family: FontAwesome;
      font-style: normal;
      font-weight: normal;
      text-decoration: inherit;
      width: 5px;
      height: 5px;
      font-size: 5px;
      bottom: 5px;
      position: absolute;
      left: 0;
    }
  }
}
.new-service {
  width: 360px;
  position: relative;
}
.new-service__head {
  padding: 10px;
  text-align: center;
  img {
    margin: 14px 0 0 23px;
  }
}
.new-service__content {
  text-align: center;
  padding: 0 0 42px 0;
  h2 {
    font-size: 18px;
    font-weight: normal;
    margin: 17px 0 21px 0;
  }
  p {
    margin: 0 47px;
    line-height: 1.7;
  }
  img {
    margin: 12px 0 19px 0;
  }
}
.solution__new{
  background: #02abc0;
  border: 1px solid #fff !important;
  @include bsizing;
}
.half-counter--ver-wrap {
  position: absolute;
  top: 20px;
}
#remind-days {
  &.half-counter {
    margin: 0;
    &:before {
      background: url('../img/empl-limit-ver.png') -2px center no-repeat;
      height: 100px;
      width: 50px;
    }
  }
  #handle2 .rs-handle  {
    background-color: transparent;
    border: 8px solid transparent;
    border-right-color: black;
    margin: -8px 0 0 14px !important;
  }
  .rs-handle:before  {
    display: block;
    content: " ";
    height: 5px;
    width: 42px;
    position: absolute;
    top: -2px;
    right: -2px;
    background: url("../img/handle-arrow.png") no-repeat;
  }
}
.half-counter {
  position: relative;
  margin: 32px 0 0 41px;
  &:before {
    content: '';
    position: absolute;
    z-index: 100;
    background: url('../img/empl-limit.png') bottom center no-repeat;
    width: 100px;
    height: 50px;
  }
  .rs-tooltip {
    font-size: 18px;
    font-weight: bold;
  }
  &--wrap {
    position: relative;
    .counter-min ,
    .counter-max {
      position: absolute;
      font-size: 9px;
      bottom: 0;
      color: #d8d8d8;
      span {
        display: block;
      }
    }
    .counter-min {
      left: 22px;
      span {
        text-align: right;
      }
    }
    .counter-max {
      right: 17px;
      span {
        text-align: left;
      }
    }
    .counter-minus ,
    .counter-plus {
      position: absolute;
      top: 78px;
      width: 20px;
      height: 20px;
      padding: 0;
      @include bradius(50%);
      i {
        margin-top: 5px;
        display: block;
      }
    }
    .counter-minus {
      left: 50px;
    }
    .counter-plus {
      right: 50px;
    }
  }
}
/* Шаблон страницы с информацией */
.info-page__header {
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 100;
}
.dialog-page {
  overflow-x: hidden !important;
  header ,
  .main-menu__list {
    position: fixed;
    z-index: 100;
    top: 70px;
  }
  .additional-features {
    display: none;
  }
  header {
    top: 0;
    width: 100%;
  }
  main {
    margin-top: 0;
  }
  .page-content {
    padding-top: 30px;
  }
}
.information {
  position: relative;
}
.information__content {
  position: relative;
  background: #fff;
  width: 100%;
  max-width: 640px;
  margin: 100px auto 80px auto;
  padding: 30px 40px;
  @include bsizing;
  h2 {
    margin-bottom: 38px;
    line-height: 1.3;
    font-size: 17px;
  }
  h3 ,  p , form{
    margin-bottom: 21px;
    line-height: 1.6;
    &:last-child {
      margin-bottom: 0;
    }
  }
}
.information__go-back ,
.information__back-to-top {
  position: absolute;
  i {
    font-size: 18px;
    margin-right: 10px;
    float: left;
    margin-top: -2px;
  }
}
.information__go-back {
  top: 37px;
  left: -71px;
}
.information__back-to-top {
  bottom: 20px;
  left: -80px;

}
.info-page-footer {
  position: fixed;
  bottom: 0;
}
.send-info__block {
  width: 180px;
  padding-bottom: 8px;
  margin: 15px auto 0 auto;
}

.js-ajax-container {
  position: fixed;
  left: 50%;
  margin-left: -290px;
  top: 50%;
  margin-top: -200px;

}
.cover-container-root {
  position: absolute;
  z-index: 2000;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background-color: rgba(29, 29, 29, 0.8);
}
.cover-container-root__overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  cursor: pointer;
}
.popup-container {
  position: relative;
  margin: 0 auto;
  width: 580px;
  max-width: 100%;
  background-color: #F6F6F6;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px;
}
.feedback__sortable {width: 100%;}
.feedback__list-wrapper {overflow-x: scroll; overflow-y: hidden; position: relative; z-index: 100;}


.no--width {
  width: auto !important;
}
@import 'desktop/media';
@import 'desktop/custom';

.print {
  display: none;
}

@media print {
  @import 'desktop/print';
}