@media (min-width: 2000px) {
	body {
	    max-width: 2000px;
	    margin: 0 auto !important;
	    overflow-x: hidden;
	}
}



@media (min-width: 300px) and (max-width: 1220px) {
body {
	min-width: 1000px;
}
.dialog-page {
	.page-content {
		margin: 0 0 0 200px;
		width: 780px;
	}
}
.container {
	min-width: 980px;
	width: 97%;
}
.video__block {
    width: 600px;
    margin: 0 -300px;
}
.video__container {
    width: 600px;
    max-width: 100%;
    margin: 0 auto;
    position: relative;
    height: 380px;
    overflow: hidden;
    }
.exdor-video {
    width: 100%;
    height: 100%;
    z-index: 100;
    position: absolute;
}
.header__exdor {
	.row {
	text-align: left;
	h1 {
		margin-left: 22%;
		}
	}
	.exdor-presentation {
		margin-left: 22%;
		padding: 10px 0 1px 0;
	}
}

.create__link {
	margin-right: 16px;
}

.security__input {
	margin-top: 4px;
	.security__input-sm {
	  width: 160px;
	}
}

.page-content-form__wrap {
	.additional-features {
		display: none;
	}		    }
	.left-400 {
	    .page-content-form__left {
	      width: calc(100% - 200px) !important;
	    }
	    &.full-request {
	    	.page-content-form__left {
	    		width: calc(100% - 320px) !important;
	    	}
	    	.page-content-form__right {
	    		width: 300px !important;
	    	}
	    }
	}
	.wide {
		.page-content-form__left {
			width: 100% !important;
		}
	}
	.sub-menu {
		margin-right: 0 !important;
	}
	.advpost__icon {
		i {
			display: none;
		}
	}

}
.requests-eq__label select {
	width: 60%;
	font-size: 11px;
	background: #fff url("../img/select__triangle-blue.png") 100% 50% no-repeat;
}