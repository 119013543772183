
body.guest-view,
body.unauth-view {
  height: auto;
  padding-bottom: 50px;
}

select:disabled,
input:disabled,
textarea:disabled{
  background: #f5f5f5 !important;

}

.js-modal-reg-description,
.js-modal-auth-description {
  padding-bottom: 10px;
}

.suggestions-suggestions {
  z-index: 1000;
}
.my-company-profile__line-label-overflow-visible {
  overflow: visible !important;
}
.my-company-profile__submit {
  width: 100%;
}
.my-partners__rcell a {
  cursor: pointer;
}
.personal-form {
  margin-bottom: 20px;
}

.fancybox-thumb-w img {
  width: 100%;
  height: auto;
}
.news__one_image {
  margin: 0 10px 10px;
}
.news__two_images {
  display: table;
  padding: 0 5px;
  margin-bottom: 10px;
}
.news__one_half_image {
  display: table-cell;
  width:50%;
  padding: 5px;
}
.news-advpost {
  position: relative;
}


.my-dialogs__row__unread {
  background: #D2F3F7 !important;
}
.my-dialogs__row__unread .my-dialogs__content .my-dialogs__name,
.my-dialogs__row__unread .my-dialogs__content .my-dialogs__text,
.my-dialogs__row__unread .my-dialogs__date
{
  /*color: #ffffff; */
}


.new-coworker__accept,
.new-coworker__cancel {
  cursor: pointer;
}

.new-coworker__role {
  margin-top: -20px;
  margin-bottom: 15px;
}
.new-coworker select {
  background: #fff url("../img/select__triangle-blue.png") right 50% no-repeat;
}
.new-coworker input {
  background: #fff;
}

.new-coworker select,
.new-coworker input {
  padding: 0 8px;
  margin-top: 5px;
  width: 100%;
  height: 30px;
  border: 1px solid #e5e5e5;
  color: #333;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  -ms-border-radius: 4px;
  border-radius: 4px;
}

.autocomplete-suggestions {
  border: 1px solid #e5e5e5;
  background: #fff;
  color: #333;
}
.page-requests {
  .autocomplete-suggestions {
    max-height: 320px;
    overflow: scroll;
  }
}
  .autocomplete-suggestion {
    padding: 7px;
  }
  .autocomplete-suggestion:hover {
    background: #b1eaf1;
    color: #333;
    cursor: pointer;
  }

  .input__wrong_data {
    border-color: rgba(255,0,0,0.4) !important;
  }

  .show-all-comments {
    cursor: pointer;
  }
  .img-responsive {
    max-width: 100%;
    height: auto;
  }

  #conversation__res {
    top: 170px;
    height: 189px;
  }

  .attachment-count-list {
    width: 120px;
    position: absolute;
    text-align: right;
    right: 150px;
    font-size: 10px;
    top: 77px;
    height: 15px;
    overflow: hidden;
    display: none;
  }

  .my-company-edit-profile__line {
    padding: 0 2px !important;
  }
  .my-company-edit-profile__title {
    width: 150px;
    text-align: right;
    padding-right: 20px;
    margin-left: 0px !important;
  }


  .js-partner__open_chat .is-blue-text {
    cursor: pointer !important;
  }

  a.after_removing_background {
    display: none;
    top: 0px;
    left: 0px;
    min-height: 100%;
    text-align: center;
    height: 100%;
    width: 100%;
    background: rgba(255,255,255,0.96);
    cursor: pointer;
    position: absolute !important;
    z-index: 3000 !important;
  }
  .conversation__row .after_removing_background {
    position: relative !important;
  }
  a.after_removing_background p {
    margin: 5px 0 0 0;
    text-transform: uppercase;
    font-weight: bold;
    color: #000;
  }
  a.after_removing_background p i {
    font-size: 30px;
  }
  a.after_removing_background:hover p {
    /* text-decoration: underline; */
  }

  .messages__opponent_typing {
    display: none;
  }
  .conversation__restore_message {
    display: none;
    text-align: center;
    margin: 20px 0;
  }


  .like_table {
    display: table;
    width: 100%;
    height: 100%;
  }
  .like_td {
    display: table-cell;
    text-align: center;
    vertical-align: middle;
  }

  .conversation__text {
    margin-bottom: 10px;
    width: 510px;
    word-wrap: break-word;
  }
  .message__unread {
    background: #d2f3f7;
  }

  #edit-message #filelist {
    width: auto;
    position: relative;
    right: 0;
    height: auto;
    overflow: hidden;
  }
  #edit-message #filelist li {
    height: 100px;
    width: 100px;
  }
  #edit-message #filelist li img {
    max-height: none;
    max-width: none;
    height: 100px;
    width: 100px;
  }

  .my-dialogs__row--active {
    cursor: pointer;
  }

  div.reply__text {
    z-index: 1000;
    position: relative;
  }

  .message__removed {
    opacity: 0.4 !important;
  }
  .ui-sortable-placeholder {
    width: 60px;
    height: 60px;
  }
  .ui-sortable-placeholder img {
    width: 100%;
    height: auto;
  }
  .filelist__clone {
    z-index: 9000;
    position: static;
  }


  .advpost__checkbox__barter {
    margin: 7px 0 0;
    width: 350px;
    float: right;
  }

  .load-more {
    display: none;
  }

  .profile-status__changer {
    width: 240px;
    border: 1px solid #F0F3F5;
  }
  .profile-status__changer__submit {
    position: absolute;
    right: -30px;
    top: 2px;
    border: none;
  }
  .pointer {
    cursor: pointer;
  }
  .partner-status__rbar a {
    cursor: pointer;
  }






  .offers__barter {
  }

  .preloader.preloader__show,
  .preloader__img.preloader__show {
    display: block ;
    opacity: 1;
  }


  .offer__price b {
    display: inline-block;
    padding-right: 15px;
  }
  .news_offers__modal_images {
    padding: 0 10px;
    text-align: center;
  }
  .news_offers__modal_images img {
    display: block;
    margin: 0 auto 8px;
  }

  .ajax__offers_full_width_container .advpost__content {
    z-index: 5;
  }

  /*
  .user-portrait__img i {
      font-family: FontAwesome;
      font-style: normal;
      font-weight: normal;
      text-decoration: inherit;
      font-size: 80px;
      top: 50%;
      left: 50%;
      margin-top: -40px;
      margin-left: -30px;
      color: #02abc0;
      position: absolute;
      z-index: 10;
  }
  */

  #enter_exdor_code--modal input {
    display: inline-block;
    width: 30px;
    height: 30px;
    font-size: 28px;
    text-align: center;
    padding: 7px;
  }
  .exdor_code__wrong,
  .exdor_code__success {
    margin-top: 15px;
    font-size: 28px;
    display: none;
  }
  .exdor_code__wrong:hover i,
  .exdor_code__wrong:hover span {
    color: #fe8e0d !important;
    border: none !important;
    text-decoration: none !important;
  }
  .exdor_code__success:hover i,
  .exdor_code__success:hover span {
    color: #02abc0 !important;
    border: none !important;
    text-decoration: none !important;
  }

  /*
  #js__select__brand_tags-selectized {
      float: none;
      left: 7px !important;
      position: relative;

  }*/
  .selectize-input {
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
    -webkit-border-radius: 4px !important;
    -moz-border-radius: 4px !important;
  }
  .selectize-input .item {
    margin: 3px 3px 3px 0 !important;
    padding: 4px 6px !important;
    border: 1px solid #ccc !important;
  }
  .selectize-control.plugin-remove_button [data-value] .remove {
    padding: 4px 0 0 0 !important;
  }
  #js__select__brand_tags-selectized {
    float: none;
    font-size: 12px;
  }

  .company-profile__card i {
    font-size: 14px;
  }

  .my-pers-profile__photo__no_background {
    /* background: none !important; */
  }




  .my-company-edit-profile__title {
    vertical-align: top;
    width: 150px !important;
  }
  .my-company-edit-profile__line span {
    /*width: 65%;*/
  }

  /*
  .my-partners__image__company_list,
  .my-partners__image__company_list img {
      width: 50px !important;
      height: 50px !important;
  }
  .my-partners__content__company_list {
      margin-left: 65px !important;
  }
  */
  .employer__control__block {
    display: none;
  }



  .my-candidats-edit-row:first-child {
    border-top: 1px solid #e5e5e5;
  }
  .my-candidats-edit-row {
    border-bottom: 1px solid #e5e5e5;
  }

  .my-candidats-edit-row .my-partners__row {
    border-top: none !important;
    border-bottom: none !important;
  }


  .suggestions-suggestions {
    height: 220px;
    overflow: scroll;
  }

  #noty_bottomRight_layout_container li {
    background-color: transparent !important;
  }
  .bottom_notification-trigger {
    background: rgba(0, 0, 0, 0.8);
    border-radius: 5px;
    z-index: 10000;
    color: #fff;
    padding: 10px;
  }
  .bottom_notification-trigger__close {
    position: absolute;
    top: 0px;
    right: 0px;
    padding: 10px;
  }
  .bottom_notification-trigger__image {
    width: 60px; height: 60px;
  }
  .bottom_notification-trigger__image img {
    border-radius: 5px;
  }
  .bottom_notification-trigger__text {
    position: absolute;
    top: 10px;
    left: 80px;
    width: 210px;
  }
  .bottom_notification-trigger__title {
    margin-bottom: 5px;
  }
  .bottom_notification-trigger__desc {
    display: block;
    height: 43px;
    overflow: hidden;
  }

  .ajax__employers_list__page_edit {
    margin-bottom: 20px;
  }
  .new-coworker__photo img,
  .coworker-info__photo img {
    z-index: 10;
  }
  .new-coworker__photo:before,
  .coworker-info__photo:before {
    content: "\f007";
    right: 33%;
    font-family: 'Font Awesome 5 Free';
    font-style: normal;
    font-weight: 900;
    text-decoration: inherit;
    font-size: 32px;
    top: 50%;
    margin-top: -18px;
    color: #02abc0;
    position: absolute;
    z-index: 10;
  }

  .user-profile__title {
    margin-bottom: 5px;
    display: block
  }
  .profile-status__text {
    /* display: inline-block; */
  }


  .send-mail__input{
    /* Пригласить руководителя float: none !important; */
  }

  .selectize-dropdown {
    z-index: 1000 !important;
  }


  .hr_delimiter {
    margin: 10px 0;
    border-color: #bdccd4;
  }
  .js-rating {
    cursor: pointer;
  }

  .req-author__image {
    color: #02abc0;
    background: #b1eaf1;
  }
  .req-author__image__comment,
  .req-author__image__comment img {
    width: 40px !important;
    height: 40px !important;
  }
  .req-author__image__comment:before {
    font-size: 22px !important;
    margin-top: -14px !important;
    right: 27% !important;
  }

  .req-item__rating {
    position: absolute;
    top: 6px;
    right: 40px;
  }
  .req-item__rating .company-profile__rating-level {
    margin-top: 0px !important;
  }
  .req-item p {
    width: 80%;
  }

  .rubl {
    font-size: 12px !important;
  }

  .req-controls__rating-level {
    margin-top: 0px !important;
    margin-bottom: 0px !important;
    width: 65px;
    display: inline-block;
    margin-right: 0;
  }
  .my-partners__image__requests_list:before {
    right: 29% !important;
  }

  .js-img-upload-container {
    display: none;
  }

  .advpost__summ-sidebar--date {
    padding: 0 6px !important;
  }

  .req-item__marked {
    cursor: pointer;
    background: rgba(2, 171, 192, 0.11);
  }
  .req-item__marked:hover {
    cursor: pointer;
    background: #feebb3;
  }

  .requests__model__equipment_scroll-wrapper {
    height: 575px;
  }


  .requests-info__photo .img-responsive {
    width: 100%;
  }

  .my-partners__row__friends_delimiter {
    background: #feebb3;
  }

  .ui-datepicker-unselectable.ui-state-disabled .ui-state-default {
    color: #aaa !important;
  }





  main.feedback--nums-6:before {
    height: calc(221px + 300px);
  }
  main.feedback--nums-7:before {
    height: calc(221px + 360px);
  }
  main.feedback--nums-8:before {
    height: calc(221px + 420px);
  }
  main.feedback--nums-9:before {
    height: calc(221px + 480px);
  }
  main.feedback--nums-10:before {
    height: calc(221px + 540px);
  }
  .feedback__list-cover .feedback__list ul li {
    margin: 14px 17px 0 3px;
  }
  input.eq__val:disabled,
  select.eq__val:disabled {
    color: #AAA;
  }

  .is-grey-text {
    color: rgba(51, 51, 51, 0.4) !important;
  }


  .image__frame-10 {
    padding: 0 10px 8px;
  }

  .advpost__footer-l {
    padding: 10px 0 10px 10px !important;
  }


  .conversation__table {
    opacity: 0.01;
  }
  /*
  .conversation {
      background: #fff;
      margin-top: 40px;
      padding-top: 10px;
      position: absolute !important;
      bottom: 0px !important;
      width: 100% !important;
  }
  */

  .dialog-page #filelist_msg {
    right: 5px !important;
  }

  .dialog-page .page-content {
    padding-top: 30px;
  }

  .user-portrait__img__editable {
    width: 180px !important;
    height: 180px !important;
    top: -5% !important;
    left: -5% !important;
  }



  .remove_btn {
    z-index:1000;
  }
  /*
  .user-portrait__helpers {
      width: 160px !important;
      height: 160px !important;
  }

  .user-portrait {
      width: 180px !important;
      height: 180px !important;
      padding: 0 !important;
  }
  */

  .user-portrait__img__edit {
    transition: 0.3s;
    opacity: 0;
    background: rgba(0,0,0,0.5) !important;
    top: 10px;
    right: 0px;
    position: absolute;
    font-size: 20px;
    padding: 0 10px;
    border-radius: 4px 0 0 4px;
    height: 30px;
    line-height: 20px;

  }
  .user-portrait__img__edit i.fas {
    color: #f2f5f6 !important;
    vertical-align: sub;
  }
  .user-portrait__img:hover .user-portrait__img__edit {
    transition: 0.3s;
    opacity: 1;
  }


  .material-block-show {
    position: relative;
    z-index: 1000;
  }
  .profile-sts__block__checkbox {
    line-height: 1.7;
    float: none;
    padding: 3px 0 5px 0;
  }
  .my-partners__image__75 {
    left: -3px;
    top: -3px;
  }

  .partner-info__link .partner-info__name {
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 75px;
    overflow: hidden;
  }


  .tooltip_offers {
    z-index: 1000 !important;
    position: relative !important;
    bottom: 0 !important;
    right: 0 !important;
    padding: 0 !important;
    font-size: 12px !important;
  }

  .show__label-c.input__wrong_data:before,
  .add_company__type__sell_label.input__wrong_data:before,
  .add_company__type__buy_label.input__wrong_data:before
  {
    border: 1px solid rgba(255, 0, 0, 0.4) !important;
  }

  .company-card__item {
    width: 22.5% !important;
  }


  #noty_bottomRight_layout_container li {
    box-shadow: none !important;
    border: 0px solid transparent !important;
  }


  .conversation__row .conversation__action a:last-child {
    border: none !important;
  }

  .is-black-text {
    color: #333;
  }

  .profile-descr {
    overflow: visible !important;
  }


  .change_login__description {
    margin-bottom: 20px;
  }

  .change_login__button {
    text-align: center;
  }
  .change_login__button input {
    width: auto;
  }

  .change_login__country_code {
    text-align: center;
  }
  .change_login__country_code .user-city {
    cursor: pointer;
    padding: 0 0 0 5px;
    width: 100px;
    color: #333;
    border: 1px solid #DDD;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background: url("../img/select__arrow-or.png") right 50% no-repeat;
    height: 30px;
    position: relative;
    font-size: 12px;
    -moz-border-radius: 4px;
    -webkit-border-radius: 4px;
    -ms-border-radius: 4px;
    border-radius: 4px;
  }
  #input__change_login__new_phone {
    border: 1px solid #DDD;
  }



  .req__col__3_4 {
    width: 70%;
    float: left;
  }
  .req__col__1_4 {
    width: 25%;
    float: right;
  }

  .requests-eq__label__full_price {
    padding-top: 0px !important;
  }


  .request_position__amount {
    position: absolute;
    top: 12px;
    left: 60%;
  }
  .request_position__report__in_stock {
    position: absolute;
    top: 35px;
    left: 60%;
  }

  .js__full_price {
    padding-top: 9px;
    float: right;
    color: #333;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background: #fff;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    -moz-border-radius: 4px;
    -webkit-border-radius: 4px;
    -ms-border-radius: 4px;
    border-radius: 4px;
  }



  .tooltip__request_positions {
    display: inline;
    position: relative;
    padding: inherit;
    font-size: inherit;
    right: inherit;
    color: #000;
    bottom: inherit;
    cursor: text;
  }
  .tooltip__request_positions .tooltip__msg {
    left: -8px;
    bottom: 30px;
  }

  .req-item__helpers--active {
    z-index: 800 !important;
  }

  .feedback__list-cover .feedback__list {
    margin-top: 41px;
  }

  .feedback__nums span.tooltip {
    margin: 0;
    width: 100%;
  }

  .main-coworkers__list .coworkers-list__item .coworker-info__photo {
    width: 75px;
    height: 75px;
  }
  .requests-step__title__compare {
    display: block;
  }

  .text-center {
    text-align: center;
  }
  .is-highlight-r, .is-highlight-l {
    margin-top: 0px;
  }




  #ajax-input-message:disabled {
    background: #fff !important;
  }
  #dialog__send_message__submit:disabled {
    background: url("../img/reply__submit.png") left center no-repeat !important;
  }



  .requests__list__employers_block__rating_and_counts {
      .my-partners__requests_found,
      .my-partners__rating-level {
          display: inline-block;
      }
      .rate__lvl {
          margin-right: 0px;
      }

  }



  .dialog__list__unread {
      color: #808080;
      font-size: 12px;
      font-weight: normal
  }

  .offer__price {
    position: relative;
    z-index: 601;
  }
  .tooltip_offers {
    border-bottom: 1px dotted;
    float: right;
    .tooltip__msg {
      bottom: 20px;
      right: 0;
    }
  }